import { Box, Input, Stack,Icon } from "@chakra-ui/react"   
import React, { useEffect, useState }  from 'react';
import { LuAlertTriangle, LuCheck, LuCheckCircle } from "react-icons/lu";
import { object, string, number, cast } from 'yup';
import BoxDialog from "../modal/BoxDialog";
import { sendFormSocket } from "../session/SessionKalyst";
export function SendForm({dados={} ,initial=[],autoComplete=true,callback,schema=object({}),children,...others}){
    const {send='socket',modulo,cmd,campo,form={}} = dados;
    const [alert,setAlert] = useState({}); 
    const [errorForm,setErrorForm] = useState({});  
    return (
        <>
            <BoxDialog param={alert}  />
            <Stack  as={'form'} w='full' h={'full'} {...others} onChange={()=>{
                setErrorForm({})
            }}  onSubmit={async (e) => {  
                    e.preventDefault();
                    let json = {};   
                    Object.keys(form).forEach((campo)=>{
                        json[campo] = form[campo];
                    })
                    new FormData(e.target).forEach(function(value, prop){ 
                        json[prop] = value;
                    });     
                    schema.validate(json,{ strict: true ,abortEarly: false}).then(function(e){  
                        if(send =='socket'){  
                            sendFormSocket({form:json, modulo,cmd,campo,callback:(result)=>{   
                                if(result.erro){
                                    result.fechar =true;
                                    result.size = 'md';  
                                    result.icon = <Icon as={LuAlertTriangle} h={{base:'50px'}} w={{base:'50px'}} color='orange.300' />
                                    setAlert(result); 
                                }else{
                                    var sendM = false;
                                    try {
                                        if(result.mensagem.length>0){
                                            sendM = true;
                                        }
                                    } catch (error) {
                                        
                                    }
                                    if(sendM){
                                        result.fechar =true;
                                        result.size = 'md';  
                                        result.icon = <Icon as={LuCheckCircle} h={{base:'50px'}} w={{base:'50px'}} color='green.300' />
                                        result.onClose= ()=>{ 
                                            if(typeof callback == 'function'){
                                                callback(result,json)
                                            } 
                                        }
                                        result.botao =[
                                            {
                                                nome:'OK', 
                                                cmd:()=>{
                                                    setAlert({exit:true}); 
                                                    if(typeof callback == 'function'){
                                                        callback(result,json)
                                                    } 
                                                }
                                            },
                                        ]
                                        setAlert(result); 
                                    }else{
                                        setAlert({exit:true}); 
                                        if(typeof callback == 'function'){
                                            callback(result,json)
                                        } 
                                    }
                                    
                                }  
                            }});
                        }else if(send =='socket'){  
                            sendFormSocket({form:json, modulo,cmd,campo,callback:(result)=>{   
                                if(result.erro){
                                    result.fechar =true;
                                    result.size = 'md';  
                                    result.icon = <Icon as={LuAlertTriangle} h={{base:'50px'}} w={{base:'50px'}} color='orange.300' />
                                    setAlert(result); 
                                }else{
                                    var sendM = false;
                                    try {
                                        if(result.mensagem.length>0){
                                            sendM = true;
                                        }
                                    } catch (error) {
                                        
                                    }
                                    if(sendM){
                                        result.fechar =true;
                                        result.size = 'md';  
                                        result.icon = <Icon as={LuCheckCircle} h={{base:'50px'}} w={{base:'50px'}} color='green.300' />
                                        result.onClose= ()=>{ 
                                            if(typeof callback == 'function'){
                                                callback(result,json)
                                            } 
                                        }
                                        result.botao =[
                                            {
                                                nome:'OK', 
                                                cmd:()=>{
                                                    setAlert({exit:true}); 
                                                    if(typeof callback == 'function'){
                                                        callback(result,json)
                                                    } 
                                                }
                                            },
                                        ]
                                        setAlert(result); 
                                    }else{
                                        setAlert({exit:true}); 
                                        if(typeof callback == 'function'){
                                            callback(result,json)
                                        } 
                                    }
                                    
                                }  
                            }});
                        }else{
                            // console.log('sucesso',json);
                        }
                        setAlert({load:true})
                        

                    }).catch(function(e) {
                        var tempError = {};
                        e["inner"].forEach((v)=>{
                            tempError[v.path] = v.message;

                        });  
                        setErrorForm(tempError)
                    }); 
                     
                }}> 
                { 
                    children.length>0?
                    children.map((filho,i)=>{  
                        if(filho!== null && typeof filho.type=='function'){  
                            return React.cloneElement(filho, {key:'form'+i,error:errorForm,initial})
                        }else{
                            try {
                                if(filho.type.displayName =='Flex' || filho.type.displayName =='Stack' || filho.type.displayName =='HStack'   ){
                                    var campo = false;
                                    if(filho.props.children.length>0){
                                        
                                        filho.props.children.forEach((sub)=>{
                                            if(typeof sub.type=='function'){  
                                               campo=true; 
                                            }
                                        }) 
                                    }
                                    if(campo){   
                                        let newSubs =filho.props.children.map((sub,pos)=>{
                                            if(typeof sub.type=='function' ){  
                                                campo=true;
                                                return React.cloneElement(sub, {key:'form'+i+'sub'+pos,error:errorForm,initial})
                                            }else{
                                                return sub;
                                            }
                                        })  
                                        return React.cloneElement(filho,{key:'form'+i,children:newSubs})
                                    }else{
                                        return filho;
                                    }
                                    
                                }else{
                                    return filho;
                                } 
                            } catch (error) {
                                return filho;
                            }
                           
                        } 
                        
                        
                    }):children
                }    
            </Stack> 
        </>
    )
}