import {  Flex,  Stack, Text, Grid, GridItem,  TagLeftIcon, TagLabel, Tag} from "@chakra-ui/react"; 
import { useEffect, useState } from "react";
import LoadBg from "../../../components/backgound/LoadBg"; 
import { PageH, PageHK } from "../../../components/heading/HeadingKalyst";
import { sendFormSocket, user } from "../../../components/session/SessionKalyst"; 
import {  LuCheckCheck, LuReceipt, LuTruck, LuUsers2 } from "react-icons/lu";  
import ListResponsive, { ListaResultadoResponsive, ListPageResponsive } from "../../../components/list/ListResponsive"; 
import { LojaSelect } from "../../../layout/painel/MenuPainel"; 
import { FaArrowTrendUp, FaBarcode, FaCartShopping, FaCreditCard, FaPix, FaScaleBalanced } from "react-icons/fa6";
import { getFilter, setFilterFiltro, setFilterPage } from "../../../components/filter/FilterDate";
import md5 from "md5";
import { FilterKalyst } from "../../../components/filter/FIlterKalyst";
import { CardPrice } from "../../../components/card/CardValues";
import { BiMoney, BiMoneyWithdraw } from "react-icons/bi";
import { ButtonKNew } from "../../../components/button/ButtonKalyst";
import ModalPage from "../../../components/modal/ModalPage";
import BoxDialog from "../../../components/modal/BoxDialog"; 
import { TempoCronometro, viewPrice } from "../../../components/Function";
import { FaBalanceScale, FaCheckDouble } from "react-icons/fa"; 
import FormWithdraw from "./FormWithdraw"; 

let session   = null;
export function PagePainelWithdraw(props){   
    const [open,setOpen]    = useState(false); 
    const [dados,setDados]  = useState(null); 
    const [edit,setEdit]    = useState({});  
    
    const hoje = new Date(); 
    let tempLoja = null;
    let tempFilter = null;
    let dFilter   = null

    function BuscarDados(reload){  
        session = md5(user.id+'withdrawv2');
        dFilter = getFilter(session);
        if(LojaSelect !==tempLoja  || dFilter.up !==tempFilter ){
            tempLoja = LojaSelect
            tempFilter = dFilter.up
            reload = true; 
        }     
        sendFormSocket({campo:'pages', modulo:'withdraw',cmd:'list',form:{reload,loja:LojaSelect,filtro:dFilter},callback:(result)=>{   
            setDados(result)
        }});
    } 
    useEffect(()=>{ 
        BuscarDados(true);
        var interV =setInterval(()=>{
            BuscarDados(false);
        },500); 
        return  ()=>{
            clearInterval(interV);
        }
    },[]);

    if(dados == null){ 
        return <LoadBg/>
    }else{ 
        if(window.location.hash!=''){
            setTimeout(()=>{
                window.location.hash ='';
                setOpen(true);
                setEdit(null);
            })
           
        } 

        const titles = [
            {name:'Nome',       campo:'title'        ,w:3}, 
            {name:'Valor',      campo:'preco'       ,w:1}, 

            {name:'Espera',  campo:'espera'   ,w:1} ,
            {name:'Registro',   campo:'registro'    ,w:1} 
        ]; 
        const boxInfo={
            v1:{value:0,info:0},
            v2:{value:0,info:0},
            v3:{value:0,info:0},
            v4:{value:0,info:0},
        }
        try {  
            boxInfo.v1.value = viewPrice(dados.saldos.saquesP);  
            boxInfo.v2.value = viewPrice(dados.saldos.saldo);  
            boxInfo.v3.value = viewPrice(dados.saldos.saquesD);
            boxInfo.v3.info  = viewPrice(dados.saldos.saques); 
            boxInfo.v4.value = viewPrice(dados.saldos.vendasH);
            boxInfo.v4.info  = viewPrice(dados.saldos.vendas);
        } catch (error) {
            
        }
        const lista = [];
        dFilter = getFilter(session);
        if(dados.reports.length>0){    
            dados.reports.forEach((cli)=>{ 
                const data = new Date(cli.criacao); 
                lista.push({
                    id:cli.id,
                    title       :   (typeof cli.user == 'undefined')?'Desconhecido':cli.user,  
                    preco       :   cli.valor!=cli.valorP && cli.status=='paid'?''+cli.valorP.toLocaleString('pt-BR',{minimumFractionDigits:2}):'R$ '+(cli.valor*0.01).toLocaleString('pt-BR',{minimumFractionDigits:2}),
                    espera    :  <Tag size={'sm'}   variant='subtle' colorScheme={'orange'}><TempoCronometro admin={true} time={data.getTime()} view = 'tempo'/> </Tag>,   
                    registro    :    new Date(data.getTime()-(data.getTimezoneOffset() * 60000)).toLocaleDateString('pt-BR', {  month: '2-digit', day: '2-digit',hour:'2-digit',minute:'2-digit'})  
                }) 
            }) 
        }   
        let fastV='';
        try {
            fastV = dFilter.filtro.fast.value;
        } catch (error) {
            
        }  
        return  ( 
            <Stack   w='full' gap={{base:'16px',md:'32px'}}  align={'flex-start'} justify='flex-start' overflowX='hidden'   minH={{base:'calc(100vh - 168px)',md:'calc(100vh - 152px)'}} pb='100px'  margin = '0 auto'  >
                <Grid
                    w={'full'}  
                    templateColumns={{base:'repeat(4, 1fr)',sm:'repeat(8, 1fr)',md:'repeat(4, 1fr)',lg:'repeat(8, 1fr)'}}
                    gap={'16px'}
                    py='16px'
                >
                    <GridItem colSpan={2}  > 
                        <CardPrice icon={BiMoney} title='Saques'  
                        value={boxInfo.v1.value}   
                        info={<Text color={'orange.500'} fontWeight='bold'>Pendente</Text>} 
                    />
                    </GridItem> 
                    <GridItem colSpan={2}  >
                        <CardPrice icon={FaCheckDouble} title='Saldo Geral'  
                        value={boxInfo.v2.value}   
                         info={<Text color={'green.500'} fontWeight='bold'>Disponível</Text>}
                    />
                    </GridItem> 
                    <GridItem colSpan={2}  >
                        <CardPrice icon={BiMoneyWithdraw} title='Saques Geral' value={boxInfo.v3.value} 
                         info={<Text color={'orange.500'} fontWeight='bold'>R$ {boxInfo.v3.info}</Text>}
                         infoM={'Saques Pago'}/>
                    </GridItem> 
                    <GridItem colSpan={2}  >
                        <CardPrice icon={FaCartShopping} title='Vendas Geral' value={boxInfo.v4.value}
                        info={<Text color={'orange.500'} fontWeight='bold'>R$ {boxInfo.v4.info}</Text>}
                        infoM={'Vendas Total'}/>
                    </GridItem> 
                </Grid>
                <PageHK>Pedidos</PageHK>  
                <Stack gap='0px' w={'full'} >   
                    <Stack   w={'full'}  p='16px' gap={'16px'}  bg='lightTema1.100' _dark={{bg:'bgMenuDark.200'}}   borderRadius={'8px'}>
                        <Text fontSize={'12px'} >Filtro Dados</Text>
                        <Flex w={'full'} gap={'16px'}>
                            <Flex w={'70%'}   >
                                <FilterKalyst type="input" title='Busca rápida' value={fastV}  onchange={(e)=>{  
                                    let {filtro:pageF}= getFilter(session); 
                                    pageF={}; 
                                    pageF.fast =   {type:'LIKE_AND',value:e,campos:['status','method']} 
                                    setFilterFiltro(session,pageF); 
                                    tempLoja = null; 
                                    setTimeout(()=>{
                                        BuscarDados(true); 
                                    },400);
                                }} />
                            </Flex>
                            <Flex w={'30%'}   >
                                <FilterKalyst title='Ordem por' value={dFilter.page.t+'_'+dFilter.page.o} dados={[
                                    {id:'id_asc'        ,   name:'Menos Recentes'},
                                    {id:'id_desc'       ,   name:'Mais Recentes'},
                                    {id:'valor_desc'    ,   name:'Maior Valor'},
                                    {id:'valor_asc'     ,   name:'Menor Valor'},
                                    {id:'user_desc'     ,   name:'Usuário de Pagamento [Z-A]'},
                                    {id:'user_asc'      ,   name:'Usuário de Pagamento [A-Z]'}, 
                                ]} onchange={(e)=>{ 
                                    if(e!=null && typeof e.id!=='undefined'){
                                        const {page:pageS}= getFilter(session) 
                                        if(e.id.split('_').length==2){
                                            pageS.t=e.id.split('_')[0];
                                            pageS.o=e.id.split('_')[1]; 
                                        }
                                        setFilterPage(session,pageS); 
                                        tempLoja = null; 
                                        setTimeout(()=>{
                                            BuscarDados(true); 
                                        },400)
                                    } 
                                }} />
                            </Flex> 
                        </Flex> 
                    </Stack>  
                    <ListaResultadoResponsive session={session}  page={dados.page}/>
                    <ListResponsive titles={titles} dados={lista} cmd={
                        [
                            { icon:<LuReceipt />,name:'Editar', click:(d)=>{  
                                setEdit(d.id);
                                setOpen(true); 
                            }}  
                        ]
                    } /> 
                    <ListPageResponsive onChange={()=>{ 
                        tempLoja = null;
                        BuscarDados(true);
                    }} session={session}  page={dados.page} />
                </Stack>
                <ButtonKNew  onClick={()=>{
                    setOpen(true);
                    setEdit(null);
                }}>Solicitar Saque</ButtonKNew>
                <ModalPage open={open} setOpen={setOpen}>
                    <FormWithdraw   open={open} setOpen={(el)=>{  setOpen(el) }} edit={edit} setEdit={setEdit}/>
                </ModalPage>
                <BoxDialog param={alert}  />
            </Stack> 
        )
    }
    
}