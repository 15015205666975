import { Flex, Stack,Icon, Text, others, IconButton, Tooltip, Skeleton, useToast, useClipboard } from "@chakra-ui/react"; 
import { LuBadgeInfo, LuCopy, LuInfo } from "react-icons/lu";
export function CardPrice({load=true,icon=null, title,value=0,info='',sigla='R$',infoM=null,...others}){
    return (
        <Skeleton isLoaded={load}>
            <Stack w={'full'} borderRadius='8px' p='16px' h={{base:'80px',md:'108px',lg:'128px'}} align='center' justify={'center'} bg='bgMenuLight.100' _dark={{bg:'bgMenuDark.100'}}   {...others}>
                <Flex w={'full'} h={{base:'18px',md:'24px'}} align='center' gap='8px'>
                    {icon!==null?<Icon as={icon} fontSize={{base:'10px',md:'20px' }} color='textLight.100' _dark={{color:'textDark.200'}} />:null}
                    <Text fontSize={{base:'10px',md:'12px',lg:'13px'}} fontWeight='900'>{title}</Text>
                </Flex>
                <Flex w={'full'} pt={{base:'0px',lg:'8px'}}   > 
                    <Text fontSize={{base:'14px',md:'22px',lg:'32px'}} fontWeight='bold' _dark={{color:'textDark.100'}}>
                        <Text as='span' fontSize={{base:'10px',md:'18px'}} _dark={{color:'textDark.100'}}   mr='5px'>{sigla}</Text>
                        {value.toString().split(',')[0]}
                        {typeof value.toString().split(',')[1]!=='undefined'?
                        <Text as='span' fontSize={{base:'10px',md:'16px'}} _dark={{color:'textDark.100'}}  >,{value.toString().split(',')[1]}</Text>
                        :null} 
                    </Text>
                </Flex>
                <Flex w={'full'} lineHeight='14px' h={'14px'} fontSize={'10px'} gap='10px'> 
                    {infoM==null?null:
                    <Tooltip hasArrow label={infoM} color='lightText.100'  _light={{ bg:'lightTema1.100',borderColor:'lightTema1.100'}} _dark={{ bg:'darkTema1.100',borderColor:'darkTema1.100',color:'darkText.100'}}>
                        <IconButton icon={<LuInfo/>} variant='ghost' minW='initial' h={'14px'}  fontSize='14px' />
                    </Tooltip>
                    
                    }{info} 
                </Flex>
            </Stack>
        </Skeleton>
    );
}
export function CardPriceMini({load=true,icon=null,variant='painel', title,value=0,info='',sigla='R$',infoM=null,...others}){
    return (
        <Skeleton isLoaded={load}>
            <Stack w={'full'} borderRadius='8px' p='16px' h={{base:'50px',md:'60px'}} align='center' justify={'center'} bg='bgMenuLight.100' _dark={{bg:'bgMenuDark.100'}}   {...others}>
                <Flex w={'full'} h={{base:'10px',md:'14px'}} align='center' gap='8px'>
                    {icon!==null?<Icon as={icon} fontSize={{base:'10px',md:'14px' }} color={variant=='admin'?'textAdmin.300':'textLight.100'} _dark={{color:variant=='admin'?'textAdmin.300':'textDark.200'}} />:null}
                    <Text fontSize={{base:'10px',md:'12px',lg:'13px'}} fontWeight='900' color={variant=='admin'?'textAdmin.300':'textLight.100'} _dark={{color:variant=='admin'?'textAdmin.300':'textDark.200'}}>{title}</Text>
                </Flex>
                <Flex w={'full'} pt={{base:'0px',lg:'0px'}}   > 
                    <Text fontSize={{base:'10px',md:'18px',lg:'22px'}} fontWeight='bold' _dark={{color:'textDark.100'}}>
                        <Text as='span' fontSize={{base:'10px',md:'14px'}} _dark={{color:'textDark.100'}}   mr='5px'>{sigla}</Text>
                        {value.toString().split(',')[0]}
                        {typeof value.toString().split(',')[1]!=='undefined'?
                        <Text as='span' fontSize={{base:'10px',md:'12px'}} _dark={{color:'textDark.100'}}  >,{value.toString().split(',')[1]}</Text>
                        :null} 
                    </Text>
                </Flex> 
            </Stack>
        </Skeleton>
    );
}
export function CardInfoText({load=true,icon=null,variant='painel',copy = true,   title,value=null,info='',obj = null,sigla='R$',infoM=null,...others}){
    const toast = useToast(); 
    const { onCopy,value:valueCopy,  setValue} = useClipboard('');
    if(value!==valueCopy){
        setTimeout(()=>{
            setValue(value);
        }) 
    } 
    return (
        <Skeleton w={'full'} isLoaded={load}>
            <Flex w={'100%'} align={'center'} justify={'space-between'}  {...others} gap={'10px'}>
                <Text   color={'textLight.200'} fontSize={{base:'12px',md:'14px', lg:'16px'}}>{title}</Text>  
                <Flex maxW={'100%'} gap={'10px'} >
                    {value!=null?
                        <Tooltip label={value}  bg={'rgba(0,0,0,0.5)'} backdropFilter='blur(60px)' color={'textAdmin.300'}> 
                            <Text noOfLines={'1'}  color={(variant=='admin'?'textAdmin.300':'textLight.300')} fontSize={{base:'12px',md:'14px', lg:'16px'}} textAlign={'right'}>{value}</Text>
                        </Tooltip>
                     :null}
                    {obj!=null?
                        obj
                     :null}
                     {copy?
                        <IconButton icon={<LuCopy/> } variant={'ghost'} size={'small'} onClick={()=>{ 
                            onCopy();
                            toast({
                                title: 'Dados Copiado',
                                description: 'Dados copiado para memória',
                                status: 'success',
                                position:'top-right',
                                duration: 6000,
                                isClosable: true,
                            }); 
                        }}/>
                     :null}
                    
                </Flex>
                
            </Flex>  
        </Skeleton>
    );
}