import { Flex, HStack, SimpleGrid, Textarea } from "@chakra-ui/react";
import { HK4, HK5, PageHK } from "../../../components/heading/HeadingKalyst";
import { InputK } from "../../../components/input/InputKalyst";
import { ButtonKalyst } from "../../../components/button/ButtonKalyst";
import { object, string } from "yup";
import { SendForm } from "../../../components/form/SendForm";
import { TextK1 } from "../../../components/text/TextKalyst";
import { LinkK } from "../../../components/button/LinkKalyst";
import { useEffect, useRef, useState } from "react";
import SelectK from "../../../components/select/SelectKalyst";
import { sendFormSocket } from "../../../components/session/SessionKalyst";
import { LojaSelect } from "../../../layout/painel/MenuPainel";
import { InputKCopy } from "../../../components/input/InputKalystCopy";

export default function FormAPI( {open,setOpen,edit = null,setEdit}){  
    const [load,setLoadD] = useState(null);
    const [base,setBase] = useState(null);
    const [reload,setReload] = useState(false);
    useEffect(()=>{
        if(edit!==null){
            setLoadD(0);
            setTimeout(()=>{
                sendFormSocket({ modulo:'api',cmd:'info',form:{id:edit},callback:(result)=>{  
                    setLoadD(1);
                    setBase(result);
                    setReload(true); 
                }});
            },1000);
        }else{
            setLoadD(1);
        } 
    },[])  
    let dados = {}; 
    let send ={loja:LojaSelect}; 
    if(base!== null){ 
        send={
            id:base.id,
            loja:LojaSelect
        };
  
        dados = {
            nome    :base.nome, 
            pub    :base.pub, 
            sec    :base.sec, 
        };
    }  
    if(!reload){
        return (
            <SendForm  dados = {{modulo:'api',cmd:'saveAPI',form:send}}  
                initial={ dados } 
                schema={ 
                    object({ 
                        nome:           string().required('Nome da API é obrigatório'),      
                    })
                } 
                callback={(result)=>{ 
                    setOpen(false); 
                }}
                gap={'10px'}
                > 
                <PageHK   textAlign='left'>API</PageHK>   
                <InputK  loaded={load} focus={false} type='name' label="Nome API"   name="nome" placeholder="Descrição do uso"   autoComplete={false}/> 
                {edit!==null?
                          
                       <InputKCopy   loaded={load} focus={false} type='password' label="Chave Secreta"   name="sec" placeholder="chave"   autoComplete={false}/> 
                     :
                    <></>
                }
                {edit!==null?
                    
                    <InputKCopy   loaded={load} focus={false} type='password' label="Chave Pública"   name="pub"  placeholder="chave"   autoComplete={false}/> 
                     :
                     <></>
                }
               
                <Flex w={'full'} gap={'20px'} align={'center'}  justify={'flex-end'}>   
                    {edit==null?<ButtonKalyst loaded={load}  >Registrar API</ButtonKalyst> :null} 
                    <LinkK onClick={()=>{
                        setOpen(false);
                        setEdit(null)
                    }}>
                        Cancelar
                    </LinkK>
                </Flex>
            </SendForm>
        )
    }else{
        setTimeout(()=>{setReload(false)})
        return null;
    }
    
}