import { Flex, Spacer, Stack, Text, Textarea ,useToast,others} from "@chakra-ui/react";
import { InputK } from "../../../components/input/InputKalyst";
import { ButtonKalyst } from "../../../components/button/ButtonKalyst"; 
import { useState } from "react";
import { sendFormSocket } from "../../../components/session/SessionAdmin";

export default function ModalAprovarDados({title='',coddoc,modulo,cmd,BuscarDados}){
 
    const [repro,setRepro] = useState(false); 
    const [obs,setObs] = useState('');
    const toast = useToast();  
    function sendDados(){
        sendFormSocket({form:{coddoc,situa:repro?'R':'N',  obs:obs }, modulo,cmd,callback:(result)=>{   
            BuscarDados(); 
            toast({
                position: 'top-right',
                title: 'Dados enviado com sucesso',
                description: "Operação realizada com sucesso!",
                status: 'success',
                containerStyle:{
                    color:'textAdmin.100',
                    bg:'bgAdmin.300'
                },
                duration: 5000,
                isClosable: true,
            });
        }});
    }  
    if(repro){
        return  (
            <Stack zIndex={30} position={'fixed'} left={'2%'} bottom={'10px'} w={'96%'} p='16px 32px'  borderRadius={'8px'} border={'1px solid'} borderColor={'bgAdmin.100'}   bg={'rgba(0,0,0,0.8)'} backdropFilter='blur(60px)'>
                <InputK as={Textarea} type='text' label="Observação"  onChange={(v)=>{ 
                    setObs(v);
                }}    placeholder="Essa observação aparecerá para o cliente"       autoComplete={false}/>
                <Flex   w={'100%'} align={'center'} gap={'16px'}>
                     <Spacer/>
                    <ButtonKalyst variant={'admin'} bg="red" opacity='0.7' _hover={{opacity:'1'}} onClick={()=>{
                        sendDados()
                        BuscarDados();
                    }} >Reprovar</ButtonKalyst>
                    <ButtonKalyst variant={'ghost'}  colorScheme="red"  border='1px solid' onClick={()=>{
                        setRepro(false);
                    }} >Cancelar</ButtonKalyst>
                </Flex>
            </Stack>
        )
    }else{
        return (
            <Stack zIndex={30} position={'fixed'} left={'2%'} bottom={'10px'} w={'96%'} p='16px 32px'  borderRadius={'8px'} border={'1px solid'} borderColor={'bgAdmin.100'}   bg={'rgba(0,0,0,0.8)'} backdropFilter='blur(60px)'>
                <Flex  w={'100%'} align={'center'} gap={'16px'}>
                    <Text color={'textAdmin.100'}>{title}</Text> <Spacer/> 
                    <ButtonKalyst variant={'admin'} onClick={()=>{
                        setRepro(false);
                        sendDados();
                    }} >Aprovar</ButtonKalyst>
                    <ButtonKalyst variant={'ghost'}  colorScheme="red"  border='1px solid' onClick={()=>{
                        setRepro(true);
                    }} >Reprovar</ButtonKalyst>
                </Flex>
            </Stack> 
        )
    }
}