import {  Flex, Image, Stack, Text,Icon,colorMode, toggleColorMode, useColorMode, Input} from "@chakra-ui/react"; 
import { useEffect, useState } from "react";
import LoadBg from "../../../components/backgound/LoadBg";
import { PageHK } from "../../../components/heading/HeadingKalyst";
import { sendFormSocket } from "../../../components/session/SessionKalyst";
import FaseDocument from "./FaseDocument"; 
 

export function PagePainelDocument(props){  
    const [dados,setDados] = useState(null); 
    const [fase,setFase] = useState(null);
    const [reset,setReset] = useState(false);
    if(fase == null){ 
        if(parseInt(window.location.hash.replace(/[^0-9]/,''))<7){
            setFase(parseInt(window.location.hash.replace(/[^0-9]/,'')));
        }else{
            setFase(0);
        } 
    }
        
    
    function BuscarDados(reload,callback){
        sendFormSocket({campo:'pages', modulo:'user',cmd:'documento',form:{reload},callback:(result)=>{ 
            setDados(result)
        }});
    } 
    useEffect(()=>{
        BuscarDados(true);
        var interV =setInterval(()=>{
            BuscarDados(false);
        },500);
        return  ()=>{
            clearInterval(interV);
        }
    },[])
    // useEffect(()=>{},[dados])
    if(dados == null || reset){ 
        return <LoadBg/>
    }else{ 
        return  (
            <Stack zIndex={3} w='full' gap={ '32px'} align={'flex-start'} justify='flex-start' maxW={'1200px'}  minH={'100vh'}   margin = '0 auto'  >
                <PageHK>Documentos</PageHK>
                <FaseDocument fase={fase}  setFase={(f)=>{
                    window.location.hash = f;
                    setFase(f)
                    setReset(true);
                    setTimeout(()=>{
                        setReset(false)
                    })
                }} BuscarDados={BuscarDados} dados={dados} /> 
            </Stack> 
        )
    }
    
}