import {useEffect, useState} from "react"  
import { Button, Center, Flex, Grid,Icon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Stack, Text } from "@chakra-ui/react"; 

import { Navigate } from "react-router-dom";
import { color } from "../../theme/theme";
import { hexToRGB } from "../Function";
import { HK2, HK3,HK4, HK5 } from "../heading/HeadingKalyst";
import { LuCheck, LuCheckCircle, LuCheckCircle2 } from "react-icons/lu";
import { ButtonKalyst } from "../button/ButtonKalyst";
import { LinkK } from "../button/LinkKalyst";

 
export default function BoxDialog({param}){ 
    const {size='md',close=false,exit,titulo='',icon=null,mensagem=[],botao=[],url='',load=false,onClose=()=>{}} =param;
    // const {size='xs',load = false,titulo='',close = false,url = '',mensagem,fechar=false,botao=[]} = (typeof props.param!='undefined')?props.param:{};
    const [open,setOpen] = useState(false);
    // const [tempD,setTempD] = useState(JSON.stringify(props.param));
    // useEffect(()=>{ },[tempD]);
    // useEffect(()=>{ },[open]); 
    const up = new Date().getTime();
    let view = {
        titulo ,
        icon,
        mensagem    :   [],
        botao       :   [],
        close 
    } 
    if(url){
        return <Navigate to={url} />
    }else{
        if(exit){
            if(open){
                setOpen(false);
            } 
        }else if(load){
            view.titulo = 'Enviando dados';
            if(!open){
                setOpen(true);
            } 
            view.close =false
            view.icon = <Spinner   size={'xl'} color='textLight.300' _dark={{color:'textDark.3s00'}}/> ;
            view.mensagem.push(
                <Flex key={'msgLoad'+up} flexDirection='row' align='center' justify='center'>
                    <Text ml='15px' >Aguarde um momento...</Text>
                </Flex>
            );
        }else{ 
    //         view.titulo = titulo; 
            view.mensagem = []; 
            if(typeof mensagem != 'undefined'){
                if(typeof mensagem =='string'){
                    view.mensagem.push(
                        <Text key={'mensgem'+up}  color={'textLight.200'} _dark={{color:'textDark.200'}}  dangerouslySetInnerHTML={{__html: mensagem}}/>
                    ) 
                }else if(Array.isArray(mensagem)){
                    mensagem.forEach((m,i)=>{ 
                        view.mensagem.push(<Text key={'m'+up+'key'+i} color={'textLight.200'} _dark={{color:'textDark.200'}} dangerouslySetInnerHTML={{__html: m.mensagem}}></Text>)
                    })
                }else{ 
                    view.mensagem = mensagem ;
                }
            }  
            if(!view.fechar && botao.length==0){
                view.botao.push(
                    <ButtonKalyst key={'botao'+up} onClick={()=>{
                        setOpen(false); 
                    }} colorScheme="whiteAlpha">OK</ButtonKalyst>
                )
            }else{
                if(botao.length>0){
                    botao.forEach((bt,pos)=>{ 
                        view.botao.push(
                            <ButtonKalyst key={'botao'+up+'bt'+pos} onClick={()=>{
                                try {
                                    bt.cmd(param);
                                } catch (error) {
                                    
                                }
                                if(!bt.close){
                                    setOpen(false);
                                } 
                            }} variant={typeof bt.variant=='undefined'?"solid":bt.variant} >{bt.nome}</ButtonKalyst>
                            // <Button key={'botao'+up+'bt'+pos} onClick={()=>{
                            //    
                            //     if(!bt.close){
                            //         setOpen(false);
                            //     } 
                            // }} colorScheme={typeof bt.color=='undefined'?"whiteAlpha":bt.color}></Button>
                        )
                    });
                }
                
            }
        }
        return (
            <Modal isCentered isOpen={open}  size={{base:'sm',sm:size}}  onClose={()=>{
                if(typeof onClose =='function'){
                    onClose();
                }
                setOpen(false);
            }} >
                <ModalOverlay  bg={'rgba('+hexToRGB(color.bgMainDark)+',0.5)' } _dark={{bg:'rgba('+hexToRGB(color.bgMainDark)+',0.5)'}} backdropFilter='blur(10px) hue-rotate(90deg)' /> 
                <ModalContent w={'100%'}  bg={'bgDialogLight.100'}  _dark={{bg:'bgDialogDark.100'}} py='20px' >
                    {view.close?<ModalCloseButton top={'20px'} right='20px'  color={'textLight.100'} _dark={{color:'textDark.100'}} _hover={{bg:'red',color:'tema.bg001',opacity:0.5}}/>: null}
                    <ModalBody h='full'  alignContent='center'  >
                        <Stack w={'full'} h='full' align={'center'} justify='center'>
                            {view.icon!=null?<Flex  color={'textLight.100'} _dark={{color:'textDark.100'}}>{view.icon}</Flex>: null}
                            {view.titulo!=''?<HK5  color={'textLight.100'} _dark={{color:'textDark.100'}}>{view.titulo}</HK5>: null}
                            {view.mensagem.length>0?  <Stack gap={'0px'}  >{view.mensagem}</Stack>  : view.mensagem }
                           
                        </Stack> 
                    </ModalBody>
                    {view.botao.length>0?<ModalFooter   ><Stack direction={'row'} gap={'10px'} w={'100%'}  align='center' justify='center'>{view.botao}</Stack></ModalFooter> :null}
                {/* //             
    //                 
    //                 
    //                 
    //                 
    //              */}
                </ModalContent>
            </Modal>
        );
    }
   
};