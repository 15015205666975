import { Button ,Skeleton,others} from "@chakra-ui/react"

export function ButtonKalyst({type='submit',loaded=true,leftIcon=null,name,variant,colorScheme='solid',children, ...others}){ 
    return (
        <Skeleton isLoaded={loaded}>
            <Button data-test={'button.'+type} type={type} leftIcon={leftIcon} colorScheme={colorScheme} variant={variant}  size={{base:'sm',md:'md',lg:'lg'}} fontSize={{base:'14px',md:'16px',lg:'18px'}} borderRadius='4px' {...others}>
            {children}
            </Button>
        </Skeleton> 
    )
}
export function ButtonKNew({type='submit',name,icon=null, children, ...others}){ 
    return (
        <Button position={'fixed'} textShadow={'lg'} leftIcon={icon} bottom={'10px'} right={'10px'} zIndex={'20'} data-test={'button.'+type} type={type}    size={{base:'sm',md:'md',lg:'lg'}} fontSize={{base:'14px',md:'16px',lg:'18px'}} borderRadius='4px' {...others}>
            {children}
        </Button>
    )
}