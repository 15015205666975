import { Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, Icon, Stack, Text } from "@chakra-ui/react";
import { getFilter, setFilterFiltro, setFilterPage } from "./FilterDate";
import { FilterKalyst } from "./FIlterKalyst";
import { ButtonKalyst } from "../button/ButtonKalyst";
import { FaFilter } from "react-icons/fa";
import { useRef, useState } from "react";
import { LinkK } from "../button/LinkKalyst";

export default function FilterPainel({dFilter={},session,onChange,ordem = [],fast=[],viewFilter = null,variant=''}){
    const [isOpen,setIsOpen] = useState(false);
    const selectForm = useRef(null)
    let fastV='';
    try {
        fastV = dFilter.filtro.fast.value;
    } catch (error) {
        fastV = '';
    }  
    const {filtro:tempF}= getFilter(session); 
    let limparFiltro = false;
    try { if(Object.keys(tempF).length>0){ limparFiltro= true; }    } catch (error) { };
   
    return (
        <Stack   w={'full'}  p='16px' gap={'16px'}  bg='lightTema1.100' _dark={{bg:'bgMenuDark.200'}}   borderRadius={'8px'}>
            <Text fontSize={'12px'}  color='gray.600' _dark={{color:'gray.300'}}   >Filtro Dados</Text>
            <Flex w={'full'} gap={'16px'}>
                <Flex w={'70%'}   >
                    <FilterKalyst type="input" title='Busca rápida' value={fastV}   variant={variant}  onchange={(e)=>{  
                        let {filtro:pageF}= getFilter(session); 
                        pageF={}; 
                        pageF.fast =   {type:'LIKE_AND',value:e,campos:fast} 
                        setFilterFiltro(session,pageF);   
                        onChange(); 
                    }} />
                </Flex>
                <Flex w={'30%'}   >
                    <FilterKalyst title='Ordem por' value={dFilter.page.t+'_'+dFilter.page.o} dados={ordem} variant={variant} onchange={(e)=>{ 
                        if(e!=null && typeof e.id!=='undefined'){
                            const {page:pageS}= getFilter(session) 
                            if(e.id.split('_').length==2){
                                pageS.t=e.id.split('_')[0];
                                pageS.o=e.id.split('_')[1]; 
                            }
                            setFilterPage(session,pageS);   
                            onChange(); 
                        } 
                    }} />
                </Flex> 
            </Flex> 
            <Flex align={'center'} gap={'16px'}>
                <ButtonKalyst leftIcon={<Icon as={FaFilter}/>} size={{base:'sm'}} variant={variant} fontSize='12px' onClick={()=>{
                     setIsOpen(true);
                }}>Filtro Avançado</ButtonKalyst>  
                {(limparFiltro)? <LinkK onClick={()=>{
                     setFilterFiltro(session,{});  
                     onChange();
                }}>Limpar Filtro</LinkK>:''} 
            </Flex> 
            <Drawer
                isOpen={isOpen}
                placement='left' 
                onClose={()=>{
                    setIsOpen(false)
                }}
            >
                <DrawerOverlay />
                <DrawerContent>
                <DrawerCloseButton mt='10px' colorScheme="red" />
                <DrawerHeader borderBottomWidth='1px'>
                    Filtro Avançado
                </DrawerHeader>

                <DrawerBody px='0px'>
                    <Stack ref={selectForm} as={'form'} w='full' h={'full'}  onChange={(e)=>{  
                        selectForm.current.onSubmit=(e)=>{
                            e.preventDefault();
                        }
                        let {filtro:pageF}= getFilter(session); 
                        try {
                            if(Object.keys(pageF).length==0){
                                pageF = {};
                            }
                        } catch (error) {
                            pageF = {};
                        }

                        let json = {};    
                        new FormData( selectForm.current).forEach(function(value, prop){ 
                            if(value!='' && prop.split('-').length==2){ 
                                json[prop] = {type:prop.split('-')[0],value:value,campos:prop.split('-')[1].split('|'),campo:prop};
                            }else{
                                if(typeof pageF[prop]!='undefined'){
                                    delete pageF[prop];
                                }
                            }
                            
                        });  
                        
                        Object.keys(json).forEach((key)=>{
                            pageF[key] = json[key];
                        });
                        setFilterFiltro(session,pageF);  
                        setTimeout(()=>{
                            onChange();
                        },400); 
                    }}  onSubmit={async (e) => {  
                            e.preventDefault(); 
                    }}> 
                    {viewFilter}
                    </Stack>
                </DrawerBody>

                {/* <DrawerFooter borderTopWidth='1px'>
                    <Button variant='outline' mr={3} onClick={()=>{
                        setIsOpen(false)
                    }}>
                    Cancel
                    </Button>
                    <Button colorScheme='blue'>Submit</Button>
                </DrawerFooter> */}
                </DrawerContent>
            </Drawer>
        </Stack> 
    ) 
}