import { Flex, Stack} from "@chakra-ui/react";
import { PageHK, SubBHK } from "../../../components/heading/HeadingKalyst";
import { InputK } from "../../../components/input/InputKalyst";
import { ButtonKalyst } from "../../../components/button/ButtonKalyst"; 
import { SendForm } from "../../../components/form/SendFormAdmin"; 
import { LinkK } from "../../../components/button/LinkKalyst";
import { useState } from "react";
import SelectK from "../../../components/select/SelectKalyst"; 

export default function FormAdminPlansPerson( {open,setOpen,edit = null,user={},setEdit,plans}){  
    // const [load,setLoadD] = useState(1);
    const [base,setBase] = useState(null); 
    const [reload,setReload] = useState(false);  
    const [pix,setPix] = useState(null); 
    const [card,setCard] = useState(null); 
    const [boleto,setBoleto] = useState(null); 
    let dados       =   {}; 
    let send        =   {};  
    if(edit!== null){
      
        dados = plans[edit]; 
        send={ variacao:dados.variacao== null?dados.id:dados.variacao,codusu:user.id,nome: "Plano - "+user.fantasia};  
        if(dados.variacao!== null){
            send.id =dados.id;
        }
    } 
    if(pix== null){
        if(Object.keys(dados).length>0){
            setPix(dados.pix);
            setCard(dados.card);
            setBoleto(dados.boleto); 
        }else{
            setPix('S');
            setCard('S');
            setBoleto('S'); 
        }
        setReload(true);
    }
    if(!reload){   
        return (
             <> 
                <PageHK   textAlign='left'>  {edit==null?'Registrar Plano':send.nome}</PageHK> 
                <Stack pt='20px'>  
                    <SendForm  dados = {{modulo:'plans',cmd:'savePlan',form:send}}   
                        callback={(result)=>{  
                            setOpen(false); 
                        }}
                        gap={'10px'}
                    > 
                        <Stack  bg='bgAdmin.200' p='10px' gap={'0px'}>
                            <SubBHK color='gray.200' fontSize='14px'  textAlign='left'>Configuração:</SubBHK>  
                            <Flex w='full' gap={'10px'}> 
                                <InputK       label="Nome"   placeholder="Nome do plano"    name="nome"   w='100%' initial={send} disabled={true}/>
                            </Flex>    
                            <Flex w='full' gap={'10px'}> 
                                <SelectK  initial={{pix:pix}} label={'Pix'}             w="100%" onChange={(e)=>{ setPix(e.target.value);   }}  placeholder="Selecione" value={[{value:'S',name:'SIM'},{value:'N',name:'Não'}]} name='pix' ></SelectK>
                                <SelectK  initial={{card:card}} label={'Cartão'}        w="100%" onChange={(e)=>{ setPix(e.target.value);   }}  placeholder="Selecione" value={[{value:'S',name:'SIM'},{value:'N',name:'Não'}]} name='card' ></SelectK>
                                <SelectK  initial={{boleto:boleto}} label={'Boleto'}    w="100%" onChange={(e)=>{ setPix(e.target.value);   }}  placeholder="Selecione" value={[{value:'S',name:'SIM'},{value:'N',name:'Não'}]} name='boleto' ></SelectK>
                            </Flex>  
                        </Stack>  
                        <Stack  bg='bgAdmin.200' p='10px' gap={'0px'}>
                            <SubBHK color='gray.200' fontSize='14px'  textAlign='left'>Taxas Gateway PIX:</SubBHK>
                            <Flex w='full' gap={'10px'} direction={{base:'column',md:'row'}}> 
                                <Flex w='100%' gap={'10px'}>
                                    <InputK  type='decimal'     label="Fixo"            placeholder="R$"        name="pixFixo"  sigla={'R$'}    step='0,00'     focus={false}   autoComplete={false} w='100%' initial={dados}/>
                                    <InputK  type='porcentagem' label="Porcentagem"     placeholder="%"         name="pixPor"   max="100"       step='0,00'     focus={false}   autoComplete={false} w='100%' initial={dados}/>
                                </Flex>
                                <Flex w='100%' gap={'10px'}>
                                    <InputK  type='porcentagem' label="Retenção"        placeholder="%"         name="pixRes"   max="100"       step='0,00'     focus={false}   autoComplete={false} w='100%' initial={dados}/>
                                    <InputK  type='integer'     label="Retenção Dias"   placeholder="Dias"      name="pixResD"                                  focus={false}   autoComplete={false} w='100%' initial={dados}/>
                                </Flex>
                            </Flex>
                        </Stack>  
                        <Stack  bg='bgAdmin.200' p='10px' gap={'0px'}>
                            <SubBHK color='gray.200' fontSize='14px'  textAlign='left'>Taxas Adquirente Card:</SubBHK>
                            <Flex w='full' gap={'10px'} direction={{base:'column',md:'row'}}> 
                                <Flex w='100%' gap={'10px'}>
                                    <InputK  type='decimal'     label="Fixo"            placeholder="R$"       name="cardFixo"  sigla={'R$'}    step='0,00'     focus={false}   autoComplete={false} w='100%' initial={dados}/>
                                    <InputK  type='porcentagem'     label="Retenção"        placeholder="%"        name="cardRes"  max="100"           step='0,00'    focus={false}   autoComplete={false} w='100%' initial={dados}/>
                                    <InputK  type='integer'     label="Retenção Dias"   placeholder="Dias"     name="cardResD"                                  focus={false}   autoComplete={false} w='100%' initial={dados}/>
                                </Flex>
                            </Flex> 
                            <Flex w='full' gap={'10px'} direction={{base:'column',md:'row'}}> 
                                <Flex w='100%' gap={'10px'}> 
                                    <InputK  type='porcentagem'     label="1X"      placeholder="00,00%"  max="100"   name="p1"   step='0,00'      w='100%' initial={dados}/>
                                    <InputK  type='porcentagem'     label="2X"      placeholder="00,00%"  max="100"   name="p2"   step='0,00'      w='100%' initial={dados}/>
                                    <InputK  type='porcentagem'     label="3X"      placeholder="00,00%"  max="100"   name="p3"   step='0,00'      w='100%' initial={dados}/>
                                </Flex>
                                <Flex w='100%' gap={'10px'}> 
                                    <InputK  type='porcentagem'     label="4X"      placeholder="00,00%"  max="100"   name="p4"   step='0,00'     w='100%' initial={dados}/>
                                    <InputK  type='porcentagem'     label="5X"      placeholder="00,00%"  max="100"    name="p5"   step='0,00'     w='100%' initial={dados}/>
                                    <InputK  type='porcentagem'     label="6X"      placeholder="00,00%"  max="100"   name="p6"   step='0,00'     w='100%' initial={dados}/>
                                </Flex>
                            </Flex>
                            <Flex w='full' gap={'10px'} direction={{base:'column',md:'row'}}> 
                                <Flex w='100%' gap={'10px'}> 
                                    <InputK  type='porcentagem'     label="7X"      placeholder="00,00%"  max="100"   name="p7"   step='0,00'     w='100%' initial={dados}/>
                                    <InputK  type='porcentagem'     label="8X"      placeholder="00,00%"  max="100"   name="p8"   step='0,00'     w='100%' initial={dados}/>
                                    <InputK  type='porcentagem'     label="9X"      placeholder="00,00%"  max="100"   name="p9"   step='0,00'     w='100%' initial={dados}/>
                                </Flex>
                                <Flex w='100%' gap={'10px'} > 
                                    <InputK  type='porcentagem'     label="10X"     placeholder="00,00%"  max="100"  name="p10"  step='0,00'     w='100%' initial={dados}/>
                                    <InputK  type='porcentagem'     label="11X"     placeholder="00,00%"  max="100"    name="p11"  step='0,00'     w='100%' initial={dados}/>
                                    <InputK  type='porcentagem'     label="12X"     placeholder="00,00%"  max="100"   name="p12"  step='0,00'     w='100%' initial={dados}/>
                                </Flex>
                            </Flex>
                        </Stack>
                        <Stack  bg='bgAdmin.200' p='10px' gap={'0px'} >
                            <SubBHK color='gray.200' fontSize='14px'  textAlign='left'>Taxas Gateway Boleto:</SubBHK>
                            <Flex w='full' gap={'10px'} direction={{base:'column',md:'row'}}> 
                                <Flex w='100%' gap={'10px'}>
                                    <InputK  type='decimal'     label="Fixo"            placeholder="R$"      name="boletoFixo"  sigla={'R$'}    step='0,00'     focus={false}   autoComplete={false} w='100%' initial={dados}/>
                                    <InputK  type='porcentagem' label="Porcentagem"     placeholder="%"             name="boletoPor"   max="100"       step='0,00'     focus={false}   autoComplete={false} w='100%' initial={dados}/>
                                </Flex>
                                <Flex w='100%' gap={'10px'}>
                                    <InputK  type='porcentagem' label="Retenção"        placeholder="%"         name="boletoRes"   max="100"          step='0,00'  focus={false}   autoComplete={false} w='100%' initial={dados}/>
                                    <InputK  type='integer'     label="Retenção Dias"   placeholder="Dias"      name="boletoResD"                                   focus={false}   autoComplete={false} w='100%' initial={dados}/>
                                </Flex>
                            </Flex>
                        </Stack>
                        <Stack  bg='bgAdmin.200' p='10px' gap={'0px'} >
                            <SubBHK color='gray.200' fontSize='14px'  textAlign='left'>Taxas Gateway Saque:</SubBHK>
                            <Flex w='full' gap={'10px'} direction={{base:'column',md:'row'}}> 
                                <Flex w='100%' gap={'10px'}>
                                    <InputK  type='decimal'     label="Fixo"            placeholder="R$"    name="saqueFixo"    sigla={'R$'}    step='0,00'     focus={false}   autoComplete={false} w='100%' initial={dados}/>
                                    <InputK  type='porcentagem' label="Porcentagem"     placeholder="%"     name="saquePor"     max="100"       step='0,00'     focus={false}   autoComplete={false} w='100%' initial={dados}/>
                                    <InputK  type='integer'     label="Saque Mínimo"       placeholder="R$"    name="saqueMin"                                    focus={false}   autoComplete={false} w='100%' initial={dados}/>
                                </Flex> 
                            </Flex>
                        </Stack>  
                        <Flex w={'full'} gap={'20px'} align={'center'}  justify={'flex-end'}>  
                            <ButtonKalyst  variant={'admin'}>
                                {edit==null?'Registrar Plano':'Salvar Plano'}
                            </ButtonKalyst>  
                            <LinkK onClick={()=>{
                                setOpen(false);
                            }}>
                                Cancelar
                            </LinkK>
                        </Flex>  
                    </SendForm> 
                </Stack>
                
                {/* 
                <InputK loaded={load}  focus={true} type='text' label="Nome"   name="nome" placeholder="Nome"     autoComplete={false}/>  
                <Flex w={'full'} gap='20px'>
                   
                    <InputK loaded={load} disabled={pessoa==''?1:0} type={pessoa=='PJ'?'cnpj':'cpf'}  label='Documento'    w="100%"  name="documento" placeholder={pessoa=='PJ'?'00.000.000/0000-00':'000.000.000-00'}   autoComplete={false}/> 
                </Flex> 
                <PageHK   textAlign='left'>Contato</PageHK> 
                <Flex w={'full'} gap='20px'> 
                    <InputK loaded={load} type='email' label="Email" name="email" placeholder="Digite seu email"  w='50%'    autoComplete={false}/>
                    <InputK loaded={load}  type='phone' label="Telefone" name="telefone" placeholder="Telefone" w='50%'   autoComplete={false}/>
                </Flex>
                <PageHK   textAlign='left'>Endereço</PageHK>  
                <Flex w={'full'} gap={{base:'10px', md:"20px"}}>
                    <InputK loaded={load}   type='cep'    label="CEP" name="cep" w={{base:'130px',md:'160px',lg:'170px'}}  placeholder="00000-000"   focus={false} autoComplete={false} />
                    <InputK loaded={load}  type='text'   label="Endereço"  name="rua"       placeholder="rua,cond,av..."   focus={false} autoComplete={false}/>
                </Flex> 
                <Flex w={'full'} gap={{base:'10px', md:"20px"}}>
                    <InputK loaded={load}  type='number' w={{base:'80px',md:'100px',lg:'120px'}}                   label="Número"    name="num"     placeholder="0"   focus={false} autoComplete={false}/>
                    <InputK loaded={load}  type='text'   w={{base:"calc(60% - 80px)",md:"calc(60% - 100px)",lg:"calc(50% - 120px)"}}      label="Complemento"  name="complemento"   placeholder={'bl,ap,lt...'}   focus={false} autoComplete={false}/>
                    <InputK loaded={load}  type='text'   w={{base:"calc(40%)",md:"calc(40%)",lg:"calc(50%)"}}           label="Bairro"  name="bairro"   placeholder={'Nome do Bairro'}   focus={false} autoComplete={false}/>
                </Flex> 
                <Flex w={'full'}  gap={{base:'10px', md:"20px"}}>
                    <InputK loaded={load}  type='text'   w="calc(40%)" label="Cidade"  name="cidade"  placeholder={'Nome da Cidade'}   focus={false}    autoComplete={false}/>
                    <InputK loaded={load}  type='text'   w="calc(40%)" label="Estado"  name="estado"  placeholder={'Nome do Estado'}   focus={false}    autoComplete={false}/>
                    <InputK loaded={load}  type='text'   w="calc(20%)" label="País"    name="pais"    placeholder={'Nome do País'}     focus={false}    autoComplete={false}/>
                </Flex> 
                */}
                
            </> 
        )
    }else{ 
        setTimeout(()=>{
            setReload(false)
        },100);
        return null;
    }
    
}




// import { Flex, HStack, SimpleGrid } from "@chakra-ui/react";
// import { HK4, HK5, PageHK } from "../../../components/heading/HeadingKalyst";
// import { InputK } from "../../../components/input/InputKalyst";
// import { ButtonKalyst } from "../../../components/button/ButtonKalyst";
// import { object, string } from "yup";
// import { SendForm } from "../../../components/form/SendForm";
// import { TextK1 } from "../../../components/text/TextKalyst";
// import { LinkK } from "../../../components/button/LinkKalyst";
// import { useRef, useState } from "react";

// export default function FormClint( {open,setOpen,dados = {}}){ 
//     const [redirect,setRedirect] = useState(null);  
//     const end = useRef(null);
//     const [bairro,setBairro]    = useState((typeof dados.bairro!=='undefined'?dados.bairro:'')); 
//     const [cidade,setCidade]    = useState((typeof dados.cidade!=='undefined'?dados.cidade:''));  
    
//     return (
//         <SendForm  dados = {{modulo:'user',cmd:'register'}}  
//             initial={ dados }

//             schema={ 
//                 object({ 
//                     nome:       string().required('Razão Social é obrigatório'),
//                     fantasia:   string().required('Nome Fantasia é obrigatório'),
//                     email:      string().required('E-mail é obrigatório'), 
//                     cnpj:       string().required('CNPJ é obrigatório'), 
//                     telefone:   string().required('Telefone é obrigatório'), 
//                 })
//             } 
//             callback={(result)=>{ 
              
//             }}
//             gap={'10px'}
//             > 
            
//         </SendForm>
//     )
// }