
import {  Flex, Image, Stack, Text,Icon,colorMode, toggleColorMode, useColorMode, Input, HStack, Switch} from "@chakra-ui/react"; 
import { ButtonKalyst } from "../../../components/button/ButtonKalyst";
import { LinkK } from "../../../components/button/LinkKalyst";
import { InputK } from "../../../components/input/InputKalyst";
import Typewriter from 'typewriter-effect';
import {   HK3, HK4 } from "../../../components/heading/HeadingKalyst";
import { SendForm } from "../../../components/form/SendForm";
import { object,string } from 'yup'; 
import { TextK1 } from "../../../components/text/TextKalyst"; 
import { useEffect, useState } from "react"; 
import { getWebsite } from "../../../theme/PageConfig";
import { Navigate } from "react-router-dom"; 
import DadosScript, { removeScript } from "../../../theme/ADSscript";
export function PagePainelRegister({title}){
    const { colorMode, toggleColorMode } = useColorMode();
    const [redirect,setRedirect] = useState(null); 
    
    useEffect(() => {
        DadosScript(getWebsite().url, 'registerGoogle'); 
        return () => {
            removeScript(getWebsite().url+'_registerGoogle'); 
        } 
      }, []); 
    return <Flex zIndex={2} w='100vw'  > 
            <Flex zIndex={3} w='full' align={'center'} justify='center' maxW={'1200px'}  minH={'100vh'}   margin = '0 auto' >
                <Flex w={{base:'600px',lg:'1200px'}} maxW = 'calc(100%)'    borderRadius={'5px'}>
                    <Stack display={{base:'none',lg:'flex'}} w={{base:'50%'}} p='0' position='relative' align={'center'} justify='center' >
                        <Image zIndex={1} position={'absolute'} h='full' w='full' src={'/assets/img/bglogin.webp'} alt='login' opacity={0.7}  borderRadius={'5px'}/>
                        <Stack zIndex={2} w='calc(100% - 40px)' align={'flex-end'} justify='center' maxW={'440px'}  gap={'15px'}>
                            <Image zIndex={3}   h='80px'  src={'/web/'+getWebsite().url+'/logo_menu_light.png'} alt='login' opacity={0.7}  borderRadius={'5px'}/>
                            <HK3   color='textLight.300'>Aqui você vende</HK3>
                            <HK3 color='white' fontWeight={'bold'}> 
                                <Typewriter
                                    options={{
                                        strings: ['Tudo', 'Sempre', 'Mais'],
                                        autoStart: true,
                                        loop: true,
                                    }}
                                />
                            </HK3> 
                        </Stack>
                    </Stack>
                    <Stack w={{base:'calc(100% - 40px)',lg:'50%'}} bg='lightTema1.100' borderRadius={'0  5px 5px 0'}  _dark={{bg:'darkTema1.100'}} align={'center'} justify='center'  m={'0 auto'} > 
                        <Stack w='calc(100% - 40px)' align={'center'} justify='center' maxW={'440px'}  gap={'15px'} py={{base:'30px'}}>
                            <Image zIndex={1}   h='80px'  src={'/web/'+getWebsite().url+'/logo_menu_dark.png'} alt='login' opacity={0.7} _dark={{display:'none'}}  borderRadius={'5px'}/>
                            <Image zIndex={1} display={'none'}   h='80px'  src={'/web/'+getWebsite().url+'/logo_menu_light.png'} alt='login' opacity={0.7} _dark={{display:'flex'}}   borderRadius={'5px'}/>
                             <SendForm  dados = {{modulo:'user',cmd:'register'}}  
                                schema={ 
                                    object({ 
                                        nome:       string().required('Razão Social é obrigatório'),
                                        fantasia:   string().required('Nome Fantasia é obrigatório'),
                                        email:      string().required('E-mail é obrigatório'), 
                                        cnpj:       string().required('CNPJ é obrigatório'), 
                                        telefone:   string().required('Telefone é obrigatório'),
                                        senha1:     string().required('Senha é obrigatória'),
                                        senha2:     string().required('Confirmar senha é obrigatória')
                                    })
                                } 
                                callback={(result)=>{   
                                    // setSession({token:result.hash.token,dados:result.hash.dados}); 
                                    setRedirect(<Navigate to={'/login'} />);
                                }}
                                gap={'10px'}
                                > 
                                <HK4 pb={{base:'0px',md:'10px',lg:'20px'}} textAlign='center'>Dados da Empresa</HK4>   
                                <InputK  type='text' label="Razão Social"   name="nome" placeholder="Razão Social"   focus={true} autoComplete={false}/>
                                <Flex w={'full'} gap='20px'>
                                    <InputK  type='text' label="Fantasia"   name="fantasia" placeholder="Nome Fantasia"   focus={false} autoComplete={false}/>
                                    <InputK  type='cnpj' label="CNPJ"   name="cnpj" placeholder="00.000.000/0000-00"   autoComplete={false}/>
                                </Flex>
                                <Flex w={'full'} gap='20px'>
                                   
                                    <InputK  type='email' label="Email" name="email" placeholder="Digite seu email"  w='50%'    autoComplete={false}/>
                                
                                    <InputK  type='phone' label="Telefone" name="telefone" placeholder="Telefone" w='50%'   autoComplete={false}/>
                                </Flex>
                                <Flex w={'full'} gap='20px'>
                                    <InputK type='password'  label="Senha" name="senha1"  placeholder="Sua senha"  autoComplete={false}/> 
                                    <InputK type='password'  label="Confirmar" name="senha2"  placeholder="Confirmar senha"   autoComplete={false}/> 
                                </Flex> 
                                <HStack w={'full'} align={'flex-end'} justify='flex-star' py='15px'> 
                                    {/* <Switch  _={{bg :'lightTema1.300'}} size='md' required={true}  />
                                    <TextK1>Li e aceito os </TextK1> 
                                    <LinkK href='/lost'>
                                        Termos e Condições de Uso
                                    </LinkK> */}
                                </HStack>
                                <ButtonKalyst w='full'>
                                    Registrar Conta
                                </ButtonKalyst>
                                <Flex w={'full'} align={'center'} justify='center' gap={'10px'} pt='15px'>
                                    <TextK1>Já tem uma conta?</TextK1> 
                                    <LinkK href='/login'>
                                        Fazer Login
                                    </LinkK>
                                </Flex>  
                            </SendForm>
                        </Stack> 
                    </Stack> 
                </Flex>
            </Flex>
            {redirect}
    </Flex>
}