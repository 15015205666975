import { Box, Text, useColorMode } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BgAnimatedLogin } from "../../components/backgound/BgAnimatedLogin"; 
import { getPainelRoutes, loadPainelRoutes } from "./PagesPainel";
import { conectarServer, user } from "../../components/session/SessionKalyst";
import { Navigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import TemaPainel from "./TemaPainel";
import { getWebsite, website } from "../../theme/PageConfig";
export default function LayoutPainel({page,location}){
    const [userS,setUserS] = useState(null);
    
    useEffect(()=>{ 
        conectarServer();
        const interV  = setInterval(()=>{  
            setUserS(user);
        },500)
        return ()=>{
            clearInterval(interV);
        }
    },[]); 
    loadPainelRoutes(); 
    let url = '';
    page.forEach((p,i)=>{
        url+= "/"+p;
    });
    let LoadPage = null;
    getPainelRoutes().forEach((p,i)=>{
        if(url.startsWith(p.url.toUpperCase())){
            if(LoadPage == null || url== p.url.toUpperCase()){
                LoadPage = p;
            }
        }
    });

    if(LoadPage == null){
        return <Text>not found</Text>
    }else{ 
        if(LoadPage.login){
            //se  logado
            if(userS !== null){
                if(typeof userS.email == 'undefined' && typeof LoadPage.redirect != 'undefined' ){
                    LoadPage=getPainelRoutes()[0]; 
                }
            }
        }else{
            //se não logado
            if(typeof userS !=='undefined' && userS !== null){
                if(typeof userS.email != 'undefined' && typeof LoadPage.redirect != 'undefined' ){
                        return <Navigate to={LoadPage.redirect}/>
                }
            }
        }
        const CompPage = LoadPage.page;
        if(userS == null){
            return <Box bg={'bgLoginLight.100'} w='100vw' minH='100vh'>
                <Helmet>
                    <title>{LoadPage.title}</title>
                    <meta name='description' content='' /> 
                    <link rel="icon" href={"/web/"+getWebsite().url+"/favicon.ico"} />
                </Helmet>
                <BgAnimatedLogin/> 
            </Box>;
        }else{ 
            return <Box bg={'bgLoginLight.100'} w='100%' minH='100vh'>
                <Helmet>
                    <title>{LoadPage.title}</title>
                    <meta name='description' content='' />
                    <link rel="icon" href={"/web/"+getWebsite().url+"/favicon.ico"} />
                </Helmet>
                <BgAnimatedLogin/>
                {LoadPage.tema!==false?<TemaPainel page={page}><CompPage page={page}/></TemaPainel> :<CompPage/> } 
            </Box>;
        }
        
    }
    
}