import { Flex, HStack, SimpleGrid, Textarea } from "@chakra-ui/react";
import { HK4, HK5, PageHK } from "../../../components/heading/HeadingKalyst";
import { InputK } from "../../../components/input/InputKalyst";
import { ButtonKalyst } from "../../../components/button/ButtonKalyst";
import { object, string } from "yup";
import { SendForm } from "../../../components/form/SendForm"; 
import { LinkK } from "../../../components/button/LinkKalyst";
import { useEffect, useRef, useState } from "react";
import SelectK from "../../../components/select/SelectKalyst";
import { sendFormSocket } from "../../../components/session/SessionKalyst";

import { ImageUpload } from "../../../components/image/ImageUpload";
import { LojaSelect } from "../../../layout/painel/MenuPainel";

export default function FormProducts( {open,setOpen,edit = null,setEdit}){  
    const [load,setLoadD] = useState(null);
    const [base,setBase] = useState(null);
    const [reload,setReload] = useState(false);
    const [imgD,setImgD] = useState({});
    useEffect(()=>{
        if(edit!==null){
            setLoadD(0);
            setTimeout(()=>{
                sendFormSocket({ modulo:'product',cmd:'info',form:{id:edit},callback:(result)=>{ 
                    setLoadD(1);
                    setBase(result); 
                    if(result.imgL!=''){
                        setImgD({
                            file :  "E",
                            link  : result.imgL,
                            width  : result.imgW,
                            height  : result.imgH
                        })
                    } 
                    setReload(true);
                   
                }});
            });
        }else{
            setLoadD(1);
        } 
    },[])  
    let dados = {};
    
    let send ={loja:LojaSelect};

    if(base!== null){ 
        send={
            id:base.id,
            loja:LojaSelect
        };
  
        dados = {
            nome    :   base.nome,
            valor   :   base.valor.toFixed(2),
            fisico  :   base.fisico,
            descricao:  base.descricao
        };
    } 
    if(!reload){
        return (
            <SendForm  dados = {{modulo:'product',cmd:'saveProduct',form:send}}  
                initial={ dados } 
                schema={ 
                    object({ 
                        nome:           string().required('Nome da Loja é obrigatório'),
                        valor:          string().required('Valor é obrigatório'),
                        fisico:         string().required('Físico é obrigatório'),  
                        descricao:      string().required('Descrição é obrigatório')
                    })
                } 
                callback={(result)=>{ 
                    setOpen(false); 
                }}
                gap={'10px'}
                > 
                
                <PageHK   textAlign='left'>Produto</PageHK>   
                <ImageUpload label={'Upload Imagem'} value={imgD} onChange={(v)=>{  
                    setImgD(v); 
                }} />
                <InputK  loaded={load} focus={true} type='name' label="Produto"   name="nome" placeholder="Nome do produto"   autoComplete={false}/> 
                <Flex w={'full'} gap='20px'> 
                    <InputK  type='decimal' step="0,00"  loaded={load}   label="Preço" leftA={'R$'} leftAW={'3em'}  name="valor" placeholder="Valor do produto"   focus={false} autoComplete={false}/>
                    <SelectK w={{base:'150px',lg:'250px'}} loaded={load}  label={'Físico?'} placeholder="Selecione" value={[{value:'S',name:'Sim'},{value:'N',name:'Não'}]} name='fisico' ></SelectK> 
                </Flex>     
                <InputK loaded={load} as={Textarea}  type='text' label="Descrição do Produto" name="descricao"    placeholder=""       autoComplete={false}/> 
                <Flex w={'full'} gap={'20px'} align={'center'}  justify={'flex-end'}> 
                    <ButtonKalyst loaded={load}  >
                        {edit==null?'Registrar Produto':'Salvar Produto'}
                    </ButtonKalyst> 
                    <LinkK onClick={()=>{
                        setOpen(false);
                        setEdit(null)
                    }}>
                        Cancelar
                    </LinkK>
                </Flex>
            </SendForm>
        )
    }else{
        setTimeout(()=>{setReload(false)},10)
        return null;
    }
    
}