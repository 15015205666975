import { Box, Button, FormControl, FormErrorMessage, FormHelperText, FormLabel, Input,InputGroup, InputLeftAddon, InputLeftElement, InputRightAddon, InputRightElement, others, Text, Icon, Skeleton} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { LuEye ,LuEyeOff } from "react-icons/lu";
import { getMaskString } from "./rhf-funcion-mask";
import { nl2br } from "../Function";
export function InputK({label='',as='',name='',loaded=true,sigla='',disabled=false,placeholder='',leftAW='4.5em',ref=null,value=null,w='full',autoComplete=true,chromeFake = false,step='0',max='',focus:defaultFocus = false, leftA=null,leftE=null,rightA=null,rightE,type='text',help='',required=false,initial={},onChange,onBlur,errorI:errorE='',error:errorForm={},variantBT='solid',minData=null,maxData=null,...others}){
    const ip = useRef(null); 
    const [input, setInput] = useState(value!=null?getMaskString({ value: value, type: type, step, max }).result:(typeof initial[name]=='undefined' || initial[name]==null)?'':getMaskString({ value: (step!='0'?initial[name].toFixed(step.length -2):initial[name].toString() )+(type=='porcentagem'?'%':''), type: type, step, max,sigla }).result);  
    const [hidden, setHidden] = useState(type==='password'?true:false);  
    const [focus, setFocos] = useState(defaultFocus); 
    const [errorI,setErrorI]= useState(errorE)
    let info   = {};
    let errorM = '';
    let isError = false;  
    if(errorI!=='' || errorE!=''){ 
        if(errorE!=''){  
            errorM= errorE;
            isError = true;
        }else if(input !==''){
            errorM= errorI;
            isError = true;
        }else{
            setErrorI('')
        } 
    }else if(Object.keys(errorForm).length>0){  
        if(typeof errorForm[name] !== 'undefined'){
            errorM= errorForm[name]
            isError = true;
        }  
    } 
    useEffect(() => {
        if (focus) {
            try {
                ip.current.focus();
            } catch (error) {
                
            } 
        }
    }, [focus]); 
    useEffect(()=>{
        // if(focus){
            // setOpen(true); 
        // }
    }) 
    let viewType = 'text';

    switch (type) {
        case 'cnjp':case 'cpf': case 'cnpj':case 'cpfcnpj': case 'number': case 'decimal':case 'porcentagem': case'agencia':case'integer':
            viewType= 'tel'
        break;
        case 'date':
              viewType = 'date'; 
        break;  
        case 'password':
            if(hidden){
                viewType = 'password';
            }else{
                viewType = 'text';
            } 
        break;  
    } 
    let open = (focus?true:false);   
        return (
            <>
                <FormControl color={'textLight'} w={w}  maxW={w}    _dark={{ color:'darkTema1.300'}}  isInvalid={isError} isRequired={isError}>
                    <FormLabel fontSize={{base:'10px',md:'12px',lg:'14px'}} _dark={{ color:'textDark.100'}} title={label} noOfLines={1} >{label}</FormLabel>
                    <Skeleton isLoaded={loaded}>
                        <InputGroup  position={'relative'} size={{base:'sm',md:'md',lg:'lg'}}    borderRadius='4px'  pr='0px'   border='1px solid' _hover={{}} borderColor={'gray.600'} {...others} >
                            {leftA===null?null:<InputLeftAddon  border='none'   borderRadius='0' bg='lightTema1.300' color={'textDark.100'} fontSize={{base:'13px',md:'14px',lg: '16px'}} px='0px' w={'calc('+leftAW+' + 0.5em)'} justifyContent={'center'}>{leftA}</InputLeftAddon>}
                            {leftE===null?null:<InputLeftElement pointerEvents='none'  fontSize='1.2em' color='textLight.300' noOfLines={'1'}>{leftE}</InputLeftElement>}
                            {disabled?
                                <Button zIndex={2}  position={'absolute'}  w='calc(100% )'   m='0 !important' noOfLines={'1'} textAlign={'left !important'} display={'block'}   cursor={'not-allowed'} variant='input' textIndent={ leftE==null?leftA==null?'0':leftAW:'1.5em'} opacity={ '0.6'}  > {input===''?placeholder:hidden?input.replace(/[^ ]/gi,'•'):input} </Button>
                            :
                                <Button zIndex={2}  position={'absolute'} w={'100%'}     m='0 !important'   textAlign={'left !important'}   display={open?'none':'block'} onFocus={()=>{  setFocos(true); }} onClick={()=>{  setFocos(true); }}  variant='input'  textIndent={ leftE==null?leftA==null?'0':leftAW:'1.5em'} opacity={input===''?'0.5':'1'}   >
                                    <Text    w='calc(100% - 10px)'  textOverflow={'ellipsis'} overflow={'hidden'} noOfLines={(as==''?null:'3')}   _dark={{color:'textDark.100'}} dangerouslySetInnerHTML={{__html: (input===''?placeholder:hidden?input.replace(/[^ ]/gi,'•'):as==""?input:nl2br(input))}}  h={as==''?'20px':'68px'} pt={(as==''?'0':'8px')} lineHeight={'20px'}  ></Text>
                                </Button>
                            } 
                            <Input  as={as} lineHeight={'20px'}  zIndex={1} position={'relative'} readOnly={autoComplete?false:!open} type={viewType}  ref={(ref==null?ip:ref)} resize={'none'}    display={'block'} opacity={open && !disabled?'1':'0'}  data-test={"input."+type}   fontFamily={'Inter'} autoComplete={name}
                            onBlur={(e)=>{ 
                                var validar = getMaskString({ value: e.target.value, type: type,  step, max  });
                                if(validar.valid){
                                    setErrorI('')
                                }else{
                                    setErrorI(validar.message)
                                } 
                                setFocos(false); 
                                if(typeof onBlur === 'function'){
                                    onBlur(e.target.value);
                                }
                                if(e.target.value.trim()!==e.target.value){
                                    setInput(e.target.value.trim()); 
                                }
                            }} 
                            onFocus={(e)=>{
                                setErrorI('')
                            }}
                            onChange={(e)=>{  
                                if(e.target.value===' '){
                                    setInput('')
                                }else{

                                    // if(type == 'decimal'){
                                    //     if(e.target.value!==''){
                                    //         setInput(getMaskString({ value: e.target.value , type: type, step, max }).result.replace('.',',')) 
                                    //     } 
                                    // }else{
                                        setInput(getMaskString({ value: e.target.value ,sigla:sigla, type: type, step, max }).result) 
                                    // } 
                                }
                                if(typeof onChange === 'function'){
                                    onChange(e.target.value,getMaskString({ value: e.target.value , type: type, step, max }));
                                }
                                
                            }} value={input} borderRadius='3px' border='none' bg='transparent' min={minData}  max={maxData}  placeholder={placeholder}   name={name} _dark={{color:'textDark.100'}}/>
                            {rightA===null?null:<InputRightAddon  border='none'  textShadow='1px 1px 1px rgba(0,0,0,0.4)'  borderRadius='0' bg='lightTema1.300' color={'textDark.100'}>{rightA}</InputRightAddon>}
                            {type==='password'?<InputRightElement cursor={'pointer'} textShadow='1px 1px 1px rgba(0,0,0,0.4)' border='none'   borderRadius='0' bg={variantBT=='admin'?'textAdmin.300':'lightTema1.300'} color={'textDark.100'} _hover={{}} onClick={()=>{
                                if(hidden){
                                    setHidden(false);
                                }else{
                                    setHidden(true);
                                } 
                            }}>{hidden?<Icon as={LuEye}  />:<Icon as={LuEyeOff}/>}</InputRightElement>:null}
                        </InputGroup>
                    </Skeleton> 
                    <Box   h={{base:'12px', md:'13px',lg:'14px'}} pt='4px' >
                        {!isError ? (
                            <FormHelperText noOfLines={1}  data-test='error-help' p={0} m='0'  h={{base:'12px', md:'13px',lg:'14px'}} fontSize={{base:'12px', md:'13px',lg:'14px'}}>
                                {help}
                            </FormHelperText>
                        ) : (
                            <FormErrorMessage  data-test='error-message'  p={0} m='0'  h={{base:'12px', md:'13px',lg:'14px'}} fontSize={{base:'12px', md:'13px',lg:'14px'}}><Text noOfLines={'1'} color={'red'} title={errorM}>{errorM}</Text></FormErrorMessage>
                        )}  

                    </Box> 
                </FormControl> 
            </>
        ); 
}