import {useEffect, useState} from "react"  
import { Heading,Button, Center, Flex, Grid,Icon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Stack, Text } from "@chakra-ui/react"; 

import { Navigate } from "react-router-dom";
import { color } from "../../theme/theme";
import { hexToRGB } from "../Function";
import { HK2, HK3,HK4, HK5, PageHK, SubHK } from "../heading/HeadingKalyst";
import { LuCheck, LuCheckCircle, LuCheckCircle2 } from "react-icons/lu";
import { ButtonKalyst } from "../button/ButtonKalyst";
import { LinkK } from "../button/LinkKalyst";
import { Heading1 } from "lucide-react";

 
export default function ModalPage({children,titulo='', isCentered=false,size='4xl',buttons =[],onClose=()=>{},open,setOpen }){  
    
    const up = new Date().getTime(); 
        return (
            <Modal isCentered={isCentered} isOpen={open}  size={{base:'sm',sm:size}}  onClose={()=>{
                if(typeof onClose =='function'){
                    onClose();
                }
                setOpen(false);
            }} >
                <ModalOverlay  bg={'rgba('+hexToRGB(color.bgMainDark)+',0.5)' } _dark={{bg:'rgba('+hexToRGB(color.bgMainDark)+',0.5)'}} backdropFilter='blur(10px) hue-rotate(90deg)' /> 
                <ModalContent w={'100%'}   bg={'bgDialogLight.100'}  _dark={{bg:'bgDialogDark.100'}} py='20px' >
                    <ModalCloseButton top={'20px'} right='20px'  color={'textLight.100'} _dark={{color:'textDark.100'}} _hover={{bg:'red',color:'tema.bg001',opacity:0.5}}/>
                     <ModalBody   alignContent='flex-start'  >
                        {children}
                    </ModalBody> 
                </ModalContent>
            </Modal>
        );
};
 