import { Input, Stack,Box,Text, Flex, Button } from "@chakra-ui/react";
import { PageHK } from "../heading/HeadingKalyst"; 
import { useState } from "react";
import { ButtonKalyst } from "../button/ButtonKalyst"; 
import { SendForm } from "../form/SendForm";
function  ViewFile({sendF,setSendF,crop,onClose}) { 
    
    return <>
         <Flex>
         <SendForm  dados = {{modulo:'user',cmd:'documento',form:sendF }}  
            callback={(result)=>{ 
            //   console.log(result);
            }}
            gap={'10px'}
            > 
            <ButtonKalyst onClick={()=>{ 
                onClose();
            }}>Confirmar arquivo</ButtonKalyst> 
            </SendForm>
            <ButtonKalyst variant={'ghost'} colorScheme="red" onClick={()=>{
                setSendF({})
            }}>Cancelar</ButtonKalyst> 
        </Flex>
        <Flex  
            pos={'relative'}
            border='transparent'
            borderRadius='10px'
            align='center' 
            h={'600px'}
            p='0'
            w='100%'
            maxH={'100%'}
            overflow={'hidden'}
            overflowY={'auto'}
            direction={'column'} 
            >  
                <embed src={sendF.link}    style={{ width:'100%',height:(sendF.tipo=='pdf'?'100%':'auto'),  position:'absolute',left:'0px',top:'0px',transform: 'translate(-50% -50%)'}} />
        </Flex>
    </>
}
export function InputUpload({props,types=[],size=1,crop=null,onClose= ()=>{}}){  
    const {title,tipo } = props;
    const [bg,setBg]= useState(false);
    const [error,setError]= useState('');  
    const [sendF,setSendF]= useState({}); 
    const typeFiles = {
        'png':'image/png',
        'jpeg':'image/jpeg',
        'jpg':'image/jpg',
        'webp':'image/webp',
        'pdf':'application/pdf'
    } 
    var formatos = '';
    types.forEach((e,i)=>{
        if(i>0){
            formatos += ',';
        }
        formatos += typeFiles[e];
    }) 
    return <Stack gap={'15px'}>
        <PageHK   textAlign='left'>{title}</PageHK>  
            {Object.keys(sendF).length>0?
                    <ViewFile sendF={sendF} setSendF={setSendF} crop={crop} onClose={onClose}/>
                :<Stack position={'relative'} align={'center'} justify={'center'} w={'100%'} h='250px' bg={bg?'lightTema1.300':'transparent'} border={'3px dashed'} color={bg?'lightTema1.200':error==''?'lightTema1.300':'red'} _dark={{color:bg?'darkTema1.200':error==''?'darkTema1.300':'red'}} borderRadius={'8px'} onDragOver={()=>{
                setBg(true)
            }} onDragLeave={()=>{
                setBg(false)
            }}   >
                <Text fontSize={{base:'14px',md:'16px',lg:'18px'}} fontWeight={'bold'} color={bg?'lightTema1.200':error==''?'lightTema1.300':'red'} _dark={{color:bg?'darkTema1.200':error==''?'darkTema1.300':'red'}} >{error==''?bg?'Solte o arquivo para enviar':'Clique ou arraste para enviar o arquivo':error}</Text>
                <Input   position={'absolute'} opacity={0} onChange={(e)=>{
                    setError('');
                    setBg(false); 
                    gerarFile(e,(file)=>{ 
                        var add = false;
                        types.forEach((t)=>{
                            if(file.tipo.toUpperCase() == t){
                                add = true
                            }
                        })
                        if(add){
                            file.codtip =tipo.id
                            switch(file.tipo.toLowerCase()){
                                case 'png':case 'jpg':case 'jpeg':
                                    if(crop == null){
                                        if(file.size>size){
                                            setError('Tamanho máximo é ('+size+'mb)')
                                        }else{
                                            setSendF(file);
                                        }
                                    }else{ 
                                        setSendF(file);
                                    }
                                break;
                                default:
                                    if(file.size>size){
                                        setError('Tamanho máximo é ('+size+'mb)')
                                    }else{
                                        setSendF(file);
                                    }
                                break;
                            
                            } 
                        }else{
                            setError('Formato de arquivo válidos são: '+JSON.stringify(types)+' ')
                        }
                        
                    }); 
                }} h={'100%'} w={'100%'} type="file"  variant={'ghost'} bg={'transparent'}/> 
            </Stack>
        }
    </Stack>
} 
function  gerarFile(event,callback) { 
    var fileS = {};   
    Object.keys(event.target.files).forEach(async (key)=> {
        var file    =   event.target.files[key];   
        var reader      =   new FileReader();
        reader.onload =  (e)=> { 
            fileS= {
                link:   e.target.result, 
                size:   (file.size*0.000001),
                nome:   file.name.split('.')[0],
                tipo:   file.name.split('.')[file.name.split('.').length-1] 
            };  
            callback(fileS);
        }; 
        reader.readAsDataURL(file); 
    });  
    return fileS;
}