import {  Flex, Image, Stack, Text,Icon,colorMode, toggleColorMode, useColorMode, Input, Grid, GridItem, SimpleGrid, Progress, Tooltip, TagLabel, Tag, Spacer, TabPanels, TabPanel, Tab, TabList, Tabs} from "@chakra-ui/react"; 
import { useEffect, useState } from "react";
import LoadBg from "../../../components/backgound/LoadBg"; 
import { PageHK, SubHK } from "../../../components/heading/HeadingKalyst";
import { sendFormSocket, user } from "../../../components/session/SessionKalyst"; 
import { LuBox, LuCheck, LuCheckCheck, LuEye, LuLineChart, LuPen, LuTrash, LuUser2, LuUsers2 } from "react-icons/lu"; 
import ListResponsive, { ListaResultadoResponsive, ListPageResponsive } from "../../../components/list/ListResponsive";
import { ButtonKNew } from "../../../components/button/ButtonKalyst";
import ModalPage from "../../../components/modal/ModalPage";
import FormClint from "./FormClient";
import { LojaSelect } from "../../../layout/painel/MenuPainel";
import BoxDialog from "../../../components/modal/BoxDialog";
import { FaCheck, FaCheckDouble, FaExchangeAlt, FaIntercom } from "react-icons/fa";
import { GoAlertFill } from "react-icons/go";
import { getFilter } from "../../../components/filter/FilterDate";
import md5 from "md5";
import FilterPainel from "../../../components/filter/FilterPainel";
import { InputK } from "../../../components/input/InputKalyst";
import SelectK from "../../../components/select/SelectKalyst";
import { DataPickerKalyst } from "../../../components/input/DataPickerKalyst";

//filtroSession 
let session   = null
//fim filtroSession
export function PagePainelClient(props){  
    const [open,setOpen]    = useState(false); 
    const [dados,setDados]  = useState(null); 
    const [edit,setEdit]    = useState({}); 
    const [alert,setAlert]    = useState({}); 
    const hoje = new Date(); 
    let tempLoja = null;
    //filtroSession
    let dFilter   = null
    session = md5(user.id+'clientV2');
    dFilter = getFilter(session);  
    let initial = {}
    try {
        Object.keys(dFilter.filtro).forEach((key)=>{
            initial[key]=dFilter.filtro[key].value;
        })
    } catch (error) {
        
    } 
    //fim filtroSession
    function BuscarDados(reload){  
        //filtroSession 
            dFilter = getFilter(session);
        //fim filtroSession
        if(LojaSelect !==tempLoja){
            tempLoja = LojaSelect
            reload = true; 
        }  
        sendFormSocket({campo:'pages', modulo:'client',cmd:'list',form:{reload,loja:LojaSelect,filtro:dFilter},callback:(result)=>{  
            setDados(result)
        }});
    } 
    useEffect(()=>{ 
        BuscarDados(true);
        var interV =setInterval(()=>{
            BuscarDados(false);
        },500);
        
        return  ()=>{
            clearInterval(interV);
        }
    },[]);

    if(dados == null){ 
        return <LoadBg/>
    }else{ 
        const titles = [
            {name:'Nome',       campo:'title'        ,w:2},
            {name:'Telefone',   campo:'phone'        ,w:3},
            {name:'E-mail',     campo:'email'        ,w:2},
            {name:'Status',     campo:'infoStatus'   ,w:2}
        ];
        const lista = []
        if(dados.client.length>0){
            dados.client.forEach((cli)=>{
                lista.push({
                    id:cli.id,
                    title:cli.name,
                    infoStatus:
                    <Flex w={'100%'}   justify='space-between' gap={'5px'}>
                        <Tooltip label='Pedidos Pago'>
                            <Tag colorScheme={'green'} w='full' >  
                                <Icon as={FaCheck} fontSize='16px'/>
                                <Spacer/>
                                <TagLabel>{cli.pago}</TagLabel>
                            </Tag>  
                        </Tooltip>
                        <Tooltip label='Pedidos Pago'>
                            <Tag colorScheme={'yellow'} w='full' >  
                                <Icon as={FaExchangeAlt} fontSize='16px'/>
                                <Spacer/>
                                <TagLabel>{cli.estorno}</TagLabel>
                            </Tag>  
                        </Tooltip>
                        <Tooltip label='Pedidos Pago'>
                            <Tag colorScheme={'red'} w='full' >  
                                <Icon as={GoAlertFill} fontSize='16px'/>
                                <Spacer/>
                                <TagLabel>{cli.cb}</TagLabel>
                            </Tag>  
                        </Tooltip> 
                    </Flex>,  
                    phone:cli.phone,
                    email:cli.email})
            }) 
        } 
        const viewFilter = (
            <Stack gap={'0px'}> 
                <Tabs variant='soft-rounded' colorScheme='green' size={'sm'}   pt={'0px'} px={'16px'}  pb='0'>
                    <TabList>
                        <Tab fontSize={'10px'}>Nome</Tab>
                        <Tab fontSize={'10px'}>Documento</Tab>
                        <Tab fontSize={'10px'}>Email</Tab>
                        <Tab fontSize={'10px'}>Telefone</Tab>
                    </TabList>
                    <TabPanels px={'0'}  pb='0'>
                        <TabPanel  px={'0'} pb='0' >
                            <InputK  focus={true}  type={'text'} label="Cliente Nome" size='sm'  name="LIKE_AND-name" placeholder={"Nome completo "}   autoComplete={false} initial={initial}/>  
                        </TabPanel>
                        <TabPanel  px={'0'} pb='0px'>
                            <InputK  focus={false}  type={'cpfcnpj'} label="Cliente Documento" size='sm'  name="LIKE_AND-document" placeholder={"cpf ou cnpj"}   autoComplete={false} initial={initial}/>
                        </TabPanel>
                        <TabPanel  px={'0'} pb='0px'>
                            <InputK  focus={false}  type={'email'} label="Cliente E-mail" size='sm'  name="LIKE_AND-email" placeholder={"email válido"}   autoComplete={false} initial={initial}/>
                        </TabPanel>
                        <TabPanel  px={'0'} pb='0px'>
                            <InputK  focus={false}  type={'phone'} label="Cliente Telefone" size='sm'  name="LIKE_AND-telefone" placeholder={"ddd + número"}   autoComplete={false} initial={initial}/>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
                <DataPickerKalyst  name="DATA_PERIODO-criacao" max='hoje'  initial={initial}/>
                <Stack gap='0px' px='16px'  >
                    {/* <SelectK label={'Método'} placeholder="Selecione" size='sm'   value={[{value:'pix',name:'PIX'},{value:'credit',name:'Crédito'},{value:'boleto',name:'Boleto'}]} name='IGUAL_AND-method' initial={initial} />
                    <SelectK label={'Status'} placeholder="Selecione" size='sm'    value={[{value:'pago',name:'Pago'},{value:'pendente',name:'Pendente'},{value:'rejeitado',name:'Rejeitado'},{value:'chargeback',name:'Chargeback'},{value:'estorno',name:'Estornado'}]} name='IGUAL_AND-status' initial={initial}/>
                    <SelectK label={'Produto'} placeholder="Selecione"  size='sm'    value={[{value:'S',name:'Físico'},{value:'N',name:'Digital'}]} name='IGUAL_AND-fisico' initial={initial}/>
                    <SelectK label={'Rastreio'} placeholder="Selecione"  size='sm'    value={[{value:'S',name:'Enviado'},{value:'N',name:'Pendente'}]} name='IGUAL_AND-rastreioEnviado' initial={initial}/>
                    <InputK  focus={false}  type={'text'} label="Código Rastreio" size='sm'  name="LIKE_AND-rastreio|linkRastreio" placeholder={"Código ou Site"}   autoComplete={false} initial={initial}/>   */}
                </Stack> 
            </Stack> 
        )  

        var tempReload = null;
        return  ( 
            <Stack zIndex={3} w='full' gap={{base:'16px',md:'32px'}} align={'flex-start'} justify='flex-start'   minH={'100vh'} pb='100px'  margin = '0 auto'  >
                <PageHK>Clientes</PageHK> 
                <Stack gap='0px' w={'full'} > 
               
                <FilterPainel dFilter={dFilter} session={session}  
                        onChange={()=>{
                            clearTimeout(tempReload);
                            tempReload = setTimeout(()=>{ 
                                tempLoja = null;
                                BuscarDados(true); 
                            },1000) 
                        }} 
                        ordem={[
                            {id:'id_desc'       ,name:'Mais Recentes'},
                            {id:'id_asc'        ,name:'Menos Recentes'},
                            {id:'telefone_desc' ,name:'Telefone [Z-A]'},
                            {id:'telefone_asc'  ,name:'Telefone [A-Z]'}, 
                            {id:'email_desc'    ,name:'Email [Z-A]'},
                            {id:'email_asc'     ,name:'Email [A-Z]'}
                        ]}
                        fast={['method','status','cliente']}
                        viewFilter={viewFilter}
                    /> 
                <ListaResultadoResponsive session={session}  page={dados.page} onChange={()=>{
                     tempLoja = null;
                     BuscarDados(true);
                }}/>
                <ListResponsive titles={titles} dados={lista} cmd={
                    [
                        { icon:<LuEye/>,name:'Editar', click:(d)=>{  
                            setEdit(d.id);
                            setOpen(true); 
                        }}  
                    ]
                } />  
                 <ListPageResponsive onChange={()=>{ 
                        tempLoja = null;
                        BuscarDados(true);
                    }} session={session}  page={dados.page} />
                </Stack>   
                <ModalPage open={open} setOpen={setOpen}>
                    <FormClint   open={open} setOpen={(el)=>{ 
                        setOpen(el)
                    }} edit={edit} setEdit={setEdit}/>
                </ModalPage>
                <BoxDialog param={alert}  />
            </Stack> 
        )
    }
    
}