import { Flex, Input, InputGroup, InputLeftAddon, Stack, Tag, Text } from "@chakra-ui/react" 
import { useState } from "react"
import { LinkK } from "../../../components/button/LinkKalyst"
function maskValue(value){
    return (value).toLocaleString('pt-BR',{minimumFractionDigits:2});
}
export default function BoxPrice({value = 0,saldo = 110,min=null,peds=0,onChange}){
    const [input,setInput] = useState(value);  
    return <Stack align={'center'}>
        <Flex gap='10px' py={'10px'}>
            <Tag cursor={'pointer'} opacity={(saldo>=20)?1:0.3}  onClick={()=>{ 
                if(saldo>=20){
                    setInput(20);  
                }
            }}>R$ 20,00</Tag>
            <Tag cursor={'pointer'} opacity={(saldo>=50)?1:0.3}  onClick={()=>{
                if(saldo>=50){
                    setInput(50); 
                }
            }}>R$ 50,00</Tag>
            <Tag cursor={'pointer'} opacity={(saldo>=100)?1:0.3} onClick={()=>{
                if(saldo>=100){
                    setInput(100); 
                } 
            }}>R$ 100,00</Tag> 
            <Tag cursor={'pointer'} opacity={(saldo>0)?1:0.3}  onClick={()=>{
                if(saldo>0){
                    setInput(saldo); 
                }
            }}>Max.</Tag>
        </Flex>
        
        <InputGroup  position={'relative'}      pr='0px'   border='0px solid' _hover={{}}   > 
            <Input   fontSize='60px'   color={'textDark.300'} onChange={(v)=>{ 
                var dados = v.target.value.replace(/[.]/g,'').replace(/,/g,'').replace('R$','');  
                if(dados!=''){
                   
                    dados = parseFloat(dados)*0.01;
                    if(min!== null && dados>min){
                        dados=min;
                    }
                    if(saldo!== null && dados>saldo){
                        dados=saldo;
                    }
                    onChange(dados*100);
                    setInput(dados); 
                }else{
                    setInput('0');
                } 
            }}  fontWeight='bold'  value={maskValue(input)} h='80px' textAlign={'center'} border='none' boxShadow='none !important' _selected={{boxShadow:'none !important'}}/>    
        </InputGroup> 
        <Flex gap={'10px'} py='10px' align={'center'}>
            <Text color={'textDark.200'}  fontSize='12px'>Saldo  </Text> <Text color={'textLight.100'} _dark={{color:'textDark.100'}} fontWeight='bold'  fontSize='12px'>R$ {maskValue(saldo)}</Text>
        </Flex>
    </Stack>
} 