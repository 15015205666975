import {Button, Flex, Grid, GridItem, IconButton, Menu, MenuButton, MenuItem, MenuList, Select, Spacer, Stack, Tag, Text } from "@chakra-ui/react";  
import { LuChevronLeft, LuChevronRight, LuGripVertical, LuInbox, LuView } from "react-icons/lu";
import { MdArrowDropDown } from "react-icons/md";
import { getFilter, setFilter, setFilterPage } from "../filter/FilterDate";

export default function ListResponsive({titles=[],dados=[],cmd=[]}){ 
    let fr =0;
    let viewStatus = null
    titles.map((t)=>{ 
        fr+=t.w; 
        if(t.campo=='status'){
            dados.map((c,i)=>{ 
                viewStatus = c[t.campo]
            });
        } 
    });  
    if(dados.length == 0){
        return ( 
            <Stack  w={'100%'}  gap={{base:'12px',md:'0'}}>
                <Flex display={{base:'none',md:'flex'}}  w={'100%'} pl='12px'  bg='lightTema1.100' _dark={{bg:'bgMenuDark.200'}}   borderRadius={'8px'}   minH={'44px'} align={'center'}   >
                     <Text>Nenhum resultado encontrado</Text>
                </Flex>  
            </Stack> 
        )
    }else{
        return ( 
            <Stack  w={'100%'}  gap={{base:'12px',md:'0'}} >
                <Flex display={{base:'none',md:'flex'}}  w={'100%'} pl='12px'  bg='lightTema1.100' _dark={{bg:'bgMenuDark.200'}}   borderRadius={'8px'}   minH={'44px'} align={'center'}   >
                    <Grid
                        w={cmd.length>0?'calc(100% - 54px)':'100%'}  
                        templateColumns={'repeat('+fr+', 1fr)'}
                        gap={'16px'}  
                    >
                        {titles.map((t,i)=>{ 
                            return (
                                <GridItem   key={'title'+i} colSpan={t.w} fontWeight={'bold'}  color='textLight.100' _dark={{color:'textDark.100'}}  textAlign={'center'}  > 
                                    {t.name}
                                </GridItem>
                            )
                        })} 
                    </Grid> 
                </Flex> 
                {dados.map((c,i)=>{ 
                    return <Flex   direction={{base:'column-reverse',md:'row'}}  key={'dados_'+i} w={'100%'}   pl='12px' bg={{base:'lightTema1.100',md:i%2?'lightTema1.100':'transparent'}} _dark={{bg:{base:'darkTema1.100',md:i%2?'bgMenuDark.200':'transparent'}}} borderRadius={'8px'}  minH={'54px'} align={'center'}        p={'0 16px'}  pt={{base:'4px',md:'0px'}}  pb={{base:'16px',md:'0px'}} >
                        <Grid
                            w={{base:'100%',md:cmd.length>0?'calc(100% - 40px)':'100%'}}  
                            templateColumns={{base:'repeat(1fr, 1fr)',md:'repeat('+fr+', 1fr)'}}
                            gap={{base:'4px',md:'16px'}}  
                        > 
                            {titles.map((t,pos)=>{  
                                return (
                                    <GridItem key={'campo_'+t.campo+'_'+i+'_'+pos} colSpan={{base:'1',md:t.w}}  noOfLines={'1'}  display={{base:t.campo == 'nome' || t.campo == 'status'?'none':'grid',md:'grid'}} > 
                                        <Flex w={'100%'}  align='center' justify={'space-between'} gap={'10px'} >
                                            <Text display ={{base:'flex',md:'none'}} color='textLight.200' _dark={{color:'textDark.200'}}  fontFamily={'InterBold'} noOfLines={'1'} fontSize={{base:'12px',md:'16px'}}  minW={'max-content'}>{t.name}</Text> 
                                            <Flex w='100%' color='textLight.200' _dark={{color:'textDark.100'}}   noOfLines={'1'} fontSize={{base:'12px',md:'16px'}} fontWeight={'500'}   textAlign={{base:'right',md:'center'}} >{c[t.campo]}</Flex>
                                        </Flex>
                                    </GridItem>
                                )
                            })} 
                        </Grid>
                        {cmd.length>0?
                            <Stack direction={{base:'row',md:'column'}} w={{base:'100%',md:'28px'}} align={'center'} justify={'center'}   pl={{base:'0',md:'28px'}}   mb={{base:'16px',md:'0px'}} borderBottom={{base:'2px solid',md:'none'}}  color='textLight.200' _dark={{color:'textDark.200'}}  >
                                 <Flex display={{base:'flex',md:'none'}} flex={'1'} color='textLight.200' _dark={{color:'textDark.100'}} align={'flex-start'}   >
                                    <Text   fontSize={'14px'}     color='textLight.200' _dark={{color:'textDark.200'}}  fontFamily={'InterBold'} >{titles[0].name}:</Text> 
                                    <Text as='span'   fontSize={'14px'}  w='full' noOfLines='1' color='textLight.200' _dark={{color:'textDark.200'}}   pl='5px'  >{ c[titles[0].campo]}</Text>
                                </Flex>
                                <Flex  display={{base:'flex',md:'none'}} >
                                    {viewStatus}
                                </Flex>
                               
                               {cmd.length==1?
                                    <IconButton icon={cmd[0].icon} bg={'transparent'}  onClick={()=>{ cmd[0].click(c); }}       w='28px'
                                    fontSize={'20px'} 
                                    variant='outline'
                                    border={'0px !important'}/>
                                :
                                <Menu >
                                    <MenuButton
                                        as={IconButton}
                                        aria-label='Options'
                                        w='28px'
                                        fontSize={'20px'}
                                        icon={<LuGripVertical  />}
                                        variant='outline'
                                        border={'0px !important'}
                                    />
                                        <MenuList bg={'rgba(137,137,137,0.1)'} backdropFilter='blur(60px)' px='5px'  >
                                            {cmd.map((m,p)=>{ 
                                                return ( <MenuItem key={'menu'+p} icon={(typeof m.icon !=='undefined'?m.icon:null)} onClick={()=>{ m.click(c); }}  bg='transparent'  borderRadius={'4px'}  color={'textLight.200'}_dark={{color:'textDark.100',_hover:{bg:'rgba(0,0,0,0.8)',color:'textDark.300'}}} _hover={{bg:'rgba(255,255,255,0.8)',color:'textDark.300'}}>{m.name}</MenuItem>)
                                            })}  
                                        </MenuList>
                                    </Menu>
                                } 
                                
                            </Stack>
                        :null}
                    </Flex>
                })} 
            </Stack> 
        )
    } 
    
}
export function ListaResultadoResponsive({session,page,onChange}){ 
    const {page:pageS}= getFilter(session);
    return <Flex w={'full'} justify='space-between' px='10px' align={'center'} h='60px'>
        {page.r>0? <Text >Resultados de {(page.i+1)} a {(page.i+page.fr)} do total de {page.r}</Text>:<Text ></Text>}
        
        <Select onChange={(e)=>{
             const {page:pageS}= getFilter(session)  
             pageS.n = e.target.value; 
             setFilterPage(session,pageS) 
             try {
                 onChange(e.target.value);
             } catch (error) {
                 
             } 
        }} icon={<MdArrowDropDown />} w='200px' size='sm' defaultValue={pageS.n>=10?pageS.n:10}  >
            <option value={10}>Mostrar  10 Resultados</option>
            <option value={25}>Mostrar  25 Resultados</option>
            <option value={50}>Mostrar  50 Resultados</option>
            <option value={100}>Mostrar  100 Resultados</option>
        </Select> 
    </Flex>
}
export function ListPageResponsive({session,page,onChange}){  
    if(page.m>1){
        var pg = [];
        var view = {}
        view[0]=true;
        view[page.m-1]=true;
        if(page.p ==0 ){
            view[page.p]=true;
            view[page.p+1]=true;
            view[page.p+2]=true; 
            view[page.p+3]=true; 
        }else if( page.p ==1){
            view[page.p]=true;
            view[page.p+1]=true;
            view[page.p+2]=true;  
        }else if(page.p == page.m-1 || page.p == page.m-2){
            view[page.m-4]=true; 
            view[page.m-3]=true;
            view[page.m-2]=true; 
        }else{
            view[page.p-1]=true;
            view[page.p]=true;
            view[page.p+1]=true; 
        }
      
        
        for(var p=0;p<page.m;p++){
            if(page.m>4){
                if(view[p]){
                    pg.push(p);
                } 
            }else{
                pg.push(p);
            }
        }  
        
        return (
            <> 
            <Flex w={'full'} justify='center' align={'center'}>
                <Button size={'md'} onClick={()=>{
                    const {page:pageS}= getFilter(session)  
                    pageS.p--; 
                    if(pageS.p<=0){
                        pageS.p= 0;
                    }    
                    setFilterPage(session,pageS) 
                    try {
                        onChange(pageS.p);
                    } catch (error) {
                        
                    } 
                }} leftIcon={<LuChevronLeft/> } variant='ghost' opacity={( page.p==0?'0':'1')} cursor={( page.p===0?'default':'')}>Anterior</Button>
                {pg.map((n)=>{
                    var pos =n; 
                    return <Button key={'pg'+n}  size={'sm'} px='0' variant='ghost' opacity={(page.p===pos?'1':'0.3')} _hover={{opacity:'1'}} onClick={()=>{
                        const {page}= getFilter(session)  
                        page.p=pos;  
                        setFilterPage(session,page) 
                        try {
                            onChange(pos);
                        } catch (error) {
                            
                        } 
                    }}>{(n+1)}</Button>
                })}     
                <Button size={'md'} onClick={()=>{
                    const {page:pageS}= getFilter(session)  
                    pageS.p++; 
                    if(pageS.p>=page.m-1){
                        pageS.p= page.m-1;
                    }    
                    setFilterPage(session,pageS) 
                    try {
                        onChange(pageS.p);
                    } catch (error) {
                        
                    } 
                }} variant='ghost' rightIcon={<LuChevronRight/> }  opacity={( page.p>=(page.m-1)?'0':'1')} cursor={( page.p>=(page.m-1)?'default':'')}>Próximo</Button>
            </Flex>
            </> 
        )
    }else{
        return null;
    }
   
}