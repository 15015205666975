import {  Flex,  Stack, Text, Grid, GridItem,  TagLeftIcon, TagLabel, Tag} from "@chakra-ui/react"; 
import { useEffect, useState } from "react";
import LoadBg from "../../../components/backgound/LoadBg"; 
import { PageH, PageHK } from "../../../components/heading/HeadingKalyst";
import { sendFormSocket, user } from "../../../components/session/SessionKalyst"; 
import {  LuReceipt, LuTruck, LuUsers2 } from "react-icons/lu";  
import ListResponsive, { ListaResultadoResponsive, ListPageResponsive } from "../../../components/list/ListResponsive"; 
import { LojaSelect } from "../../../layout/painel/MenuPainel"; 
import { FaArrowTrendUp, FaBarcode, FaCreditCard, FaPix } from "react-icons/fa6";
import { getFilter, setFilterFiltro, setFilterPage } from "../../../components/filter/FilterDate";
import md5 from "md5";
import { FilterKalyst } from "../../../components/filter/FIlterKalyst";
import { CardPrice } from "../../../components/card/CardValues";
import { BiMoney } from "react-icons/bi";
import { ButtonKNew } from "../../../components/button/ButtonKalyst";
import ModalPage from "../../../components/modal/ModalPage";
import BoxDialog from "../../../components/modal/BoxDialog"; 
import { MdCardTravel, MdOutlineCreditCardOff, MdOutlineMultipleStop, MdPayment, MdPayments, MdSdCardAlert } from "react-icons/md";
import { viewPrice } from "../../../components/Function";
import InfoTransaction from "./InfoTransaction";

let session   = null;
export function PagePainelTransaction(props){  
    const [open,setOpen]    = useState(false); 
    const [dados,setDados]  = useState(null); 
    const [edit,setEdit]    = useState({});   
    const [ViewBox,setViewBox]    = useState({});  
    
    const hoje = new Date(); 
    let tempLoja = null;
    let tempFilter = null;
    let dFilter   = null
    function BuscarDados(reload){  
        session = md5(user.id+'transactionV2');
        dFilter = getFilter(session);
        if(LojaSelect !==tempLoja  || dFilter.up !==tempFilter ){
            tempLoja = LojaSelect
            tempFilter = dFilter.up
            reload = true; 
        }     
        sendFormSocket({campo:'pages', modulo:'transaction',cmd:'list',form:{reload,filtro:dFilter},callback:(result)=>{ 
            setDados(result)
        }});
    } 
    useEffect(()=>{ 
        BuscarDados(true);
        var interV =setInterval(()=>{
            BuscarDados(false);
        },500); 
        return  ()=>{
            clearInterval(interV);
        }
    },[]);

    if(dados == null){ 
        return <LoadBg/>
    }else{ 
        const methods={
            'PIX':     {icon:FaPix,color:'green'},
            'Crédito': {icon:FaCreditCard,color:'blue'},
            'Boleto':  {icon:FaBarcode,color:'orange'} 
        }
        const status={
            Pago:{name:'Pago',color:'green'},
            Devolvido: {name:'Devolvido',color:'red'},
            Cancelado: {name:'Cancelado',color:'red'},
            Pendente: {name:'Pendente',color:'orange'} , 
        }
        const titles = [ 
            {name:'Loja',       campo:'nome'        ,w:2}, 
            {name:'Status',     campo:'status'      ,w:1},
            {name:'Método',     campo:'adq'         ,w:1}, 
            {name:'Valor',      campo:'valor'       ,w:1}, 
            {name:'Registro',   campo:'registro'    ,w:1} 
        ];
        
        const boxInfo={
            v1:{value:0,info:0},
            v2:{value:0,info:0},
            v3:{value:0,info:0},
            v4:{value:0,info:0},
        } 
        try {
            boxInfo.v1.value = viewPrice(dados.saldos.saldo);
            boxInfo.v1.info  = (dados.saldos.saldoP*0.01).toLocaleString('pt-BR',{minimumFractionDigits:2});
            
            boxInfo.v2.value = (dados.saldos.saques*0.01).toLocaleString('pt-BR',{minimumFractionDigits:2});
            boxInfo.v2.info  = (dados.saldos.saquesP*0.01).toLocaleString('pt-BR',{minimumFractionDigits:2});
            
            boxInfo.v3.value = (dados.saldos.estorno*0.01).toLocaleString('pt-BR',{minimumFractionDigits:2});
            
            
            boxInfo.v4.value = (dados.saldos.chargeback*0.01).toLocaleString('pt-BR',{minimumFractionDigits:2});
            
        } catch (error) {
            
        }
        const lista = [];
        dFilter = getFilter(session);
        if(dados.transactions.length>0){    
            dados.transactions.forEach((cli)=>{ 
                const data = new Date(cli.criacao); 
                lista.push({
                    id:cli.id,
                    title       :   cli.transacao, 
                    status      :   (
                        <Tag size={'sm'}  variant='subtle' colorScheme={typeof status[cli.status] !=='undefined'?status[cli.status].color:'gray'}> 
                            <TagLabel>{ typeof status[cli.status] !=='undefined'?status[cli.status].name:cli.status}</TagLabel>
                        </Tag>
                    ), 
                    nome        :   <Text color='textLight.100' _dark={{color:'textDark.100'}} >{cli.loja}</Text>, 
                    adq         :   (
                        <Tag size={'sm'}  variant='subtle' colorScheme={methods[cli.transacao].color}>
                            <TagLeftIcon boxSize='12px' as={methods[cli.transacao].icon} />
                            <TagLabel>{cli.transacao.toUpperCase()}</TagLabel>
                        </Tag> 
                    ), 
                    valor       :   'R$ '+(cli.valor*0.01).toLocaleString('pt-BR',{minimumFractionDigits:2}), 
                    registro    :    new Date(data.getTime()-(data.getTimezoneOffset() * 60000)).toLocaleDateString('pt-BR', {  month: '2-digit', day: '2-digit',hour:'2-digit',minute:'2-digit'})
                }) 
            }) 
        }   
        let fastV='';
        try {
            fastV = dFilter.filtro.fast.value;
        } catch (error) {
            
        }  
        return  ( 
            <Stack   w='full' gap={{base:'16px',md:'32px'}}  align={'flex-start'} justify='flex-start' overflowX='hidden'   minH={{base:'calc(100vh - 168px)',md:'calc(100vh - 152px)'}} pb='100px'  margin = '0 auto'  >
                <Grid  w={'full'}    templateColumns={{base:'repeat(4, 1fr)',sm:'repeat(8, 1fr)',md:'repeat(4, 1fr)',lg:'repeat(8, 1fr)'}} gap={'16px'}  py='16px' >
                    <GridItem colSpan={2}  > 
                        <CardPrice icon={BiMoney} title='Saldo Total'  value={boxInfo.v1.value}  
                        info={<Text color={'orange.500'} fontWeight='bold'>R$ {boxInfo.v1.info}</Text>}
                        infoM={'Saldo a Liberar'}
                    />
                    </GridItem> 
                    <GridItem colSpan={2}  >
                        <CardPrice icon={MdPayments} title='Saque Total'  value={boxInfo.v2.value} 
                         info={<Text color={'orange.500'} fontWeight='bold'>R$ {boxInfo.v2.info}</Text>} 
                         infoM={'Saque pendente'}
                         />
                    </GridItem> 
                    <GridItem colSpan={2}  >
                        <CardPrice icon={MdOutlineMultipleStop} title='Estorno Total' value={boxInfo.v3.value}   />
                    </GridItem> 
                    <GridItem colSpan={2}  >
                        <CardPrice icon={MdOutlineCreditCardOff } title='ChargeBack Total' value={boxInfo.v4.value}   />
                    </GridItem> 
                </Grid>
                <PageHK>Transações</PageHK>  
                <Stack gap='0px' w={'full'} >   
                    <Stack   w={'full'}  p='16px' gap={'16px'}  bg='lightTema1.100' _dark={{bg:'bgMenuDark.200'}}   borderRadius={'8px'}>
                        <Text fontSize={'12px'} >Filtro Dados</Text>
                        <Flex w={'full'} gap={'16px'}>
                            <Flex w={'70%'}   >
                                <FilterKalyst type="input" title='Busca rápida' value={fastV}  onchange={(e)=>{  
                                    let {filtro:pageF}= getFilter(session); 
                                    pageF={}; 
                                    pageF.fast =   {type:'LIKE_AND',value:e,campos:['status','method']} 
                                    setFilterFiltro(session,pageF); 
                                    tempLoja = null; 
                                    setTimeout(()=>{
                                        BuscarDados(true); 
                                    },400);
                                }} />
                            </Flex>
                            <Flex w={'30%'}   >
                                <FilterKalyst title='Ordem por' value={dFilter.page.t+'_'+dFilter.page.o} dados={[
                                    {id:'id_desc',name:'Mais Recentes'},
                                    {id:'id_asc',name:'Menos Recentes'},
                                    {id:'valor_desc',name:'Maior Valor'},
                                    {id:'valor_asc',name:'Menor Valor'},
                                    {id:'paymentMethod_desc',name:'Método de Pagamento [Z-A]'},
                                    {id:'paymentMethod_asc',name:'Método de Pagamento [A-Z]'},
                                    {id:'status_desc',name:'Status [Z-A]'},
                                    {id:'status_asc',name:'Status [A-Z]'}
                                ]} onchange={(e)=>{ 
                                    if(e!=null && typeof e.id!=='undefined'){
                                        const {page:pageS}= getFilter(session) 
                                        if(e.id.split('_').length==2){
                                            pageS.t=e.id.split('_')[0];
                                            pageS.o=e.id.split('_')[1]; 
                                        }
                                        setFilterPage(session,pageS); 
                                        tempLoja = null; 
                                        setTimeout(()=>{
                                            BuscarDados(true); 
                                        },400)
                                    } 
                                }} />
                            </Flex> 
                        </Flex> 
                    </Stack>  
                    <ListaResultadoResponsive session={session}  page={dados.page}/>
                    <ListResponsive titles={titles} dados={lista} cmd={
                        [
                            { icon:<LuReceipt />,name:'Editar', click:(d)=>{  
                                setEdit(d.id);
                                setOpen(true); 
                                setViewBox(
                                    <InfoTransaction  open={open} setOpen={(el)=>{ 
                                        setOpen(el)
                                    }} edit={d.id} setEdit={setEdit}/>
                                )
                            }}  
                        ]
                    } /> 
                    <ListPageResponsive onChange={()=>{ 
                        tempLoja = null;
                        BuscarDados(true);
                    }} session={session}  page={dados.page} />
                </Stack> 
            
                <ModalPage open={open} size='md' setOpen={setOpen}>
                    {ViewBox} 
                </ModalPage>
                <BoxDialog param={alert}  />
            </Stack> 
        )
    }
    
}