import { Flex, HStack, SimpleGrid, Textarea } from "@chakra-ui/react";
import { HK4, HK5, PageHK } from "../../../components/heading/HeadingKalyst";
import { InputK } from "../../../components/input/InputKalyst";
import { ButtonKalyst } from "../../../components/button/ButtonKalyst";
import { object, string } from "yup";
import { SendForm } from "../../../components/form/SendForm";
import { TextK1 } from "../../../components/text/TextKalyst";
import { LinkK } from "../../../components/button/LinkKalyst";
import { useEffect, useRef, useState } from "react";
import SelectK from "../../../components/select/SelectKalyst";
import { sendFormSocket } from "../../../components/session/SessionKalyst";

export default function FormStore( {open,setOpen,edit = null,setEdit}){  
    const [load,setLoadD] = useState(null);
    const [base,setBase] = useState(null);
    const [reload,setReload] = useState(false);
    useEffect(()=>{
        if(edit!==null){
            setLoadD(0);
            setTimeout(()=>{
                sendFormSocket({ modulo:'store',cmd:'info',form:{id:edit},callback:(result)=>{ 
                    setLoadD(1);
                    setBase(result);
                    setReload(true);
                   
                }});
            },1000);
        }else{
            setLoadD(1);
        } 
    },[])  
    let dados = {};
    
    let send ={};
    if(base!== null){
        send={id:base.id};
        dados = {
            nome:base.nome,
            pais:base.pais,
            endereco:base.endereco,
            telefone:base.telefone,
            email:base.email,
        };
    } 
    if(!reload){
        return (
            <SendForm  dados = {{modulo:'store',cmd:'saveStore',form:send}}  
                initial={ dados } 
                schema={ 
                    object({ 
                        nome:           string().required('Nome da Loja é obrigatório'),
                        pais:           string().required('Pais é obrigatório'),
                        telefone:       string().required('Telefone é obrigatório'),  
                        endereco:       string().required('Endereço é obrigatório')
                    })
                } 
                callback={(result)=>{ 
                    setOpen(false); 
                }}
                gap={'10px'}
                > 
                <PageHK   textAlign='left'>Loja</PageHK>   
                <Flex w={'full'} gap='20px'>
                    <InputK  loaded={load} focus={true} type='name' label="Nome da Loja"   name="nome" placeholder="Nome da Loja"   autoComplete={false}/>
                    <SelectK loaded={load}  label={'País'} placeholder="Selecione" value={[{value:'Brasil',name:'Brasil'}]} name='pais' ></SelectK> 
                </Flex>          
                <PageHK   textAlign='left'>Contato</PageHK> 
                <Flex w={'full'} gap='20px'> 
                    <InputK loaded={load} type='email' label="Email" name="email" placeholder="Email da loja"  w='50%'    autoComplete={false}/>
                    <InputK loaded={load}  type='phone' label="Telefone" name="telefone" placeholder="Telefone da loja" w='50%'   autoComplete={false}/>
                </Flex>
                <PageHK loaded={load}  textAlign='left'>Endereço</PageHK>  
                <InputK loaded={load} as={Textarea}  type='text' label="Endereço da loja" name="endereco"    placeholder=""       autoComplete={false}/>
                           
                <Flex w={'full'} gap={'20px'} align={'center'}  justify={'flex-end'}> 
                    <ButtonKalyst loaded={load}  >
                        {edit==null?'Registrar Loja':'Salvar Loja'}
                    </ButtonKalyst> 
                    <LinkK onClick={()=>{
                        setOpen(false);
                        setEdit(null)
                    }}>
                        Cancelar
                    </LinkK>
                </Flex>
            </SendForm>
        )
    }else{
        setTimeout(()=>{setReload(false)})
        return null;
    }
    
}